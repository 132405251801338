/**
 * В будущем, если будет прикручена система локализации -
 * использовать эти файлы
 */
export const localization = {
	common: {
		create: 'Создать',
		remove: 'Удалить',
		save: 'Сохранить',
		cancel: 'Отмена',
		createdAt: 'Дата создания',
		updatedAt: 'Дата обновления',
		yes: 'Да',
		clear: 'Очистить',
		kg: 'кг',
		rur: 'руб',
		order: 'Заказать',
		apply: 'Подтвердить',
		find: 'Найти',
		add: 'Добавить',
		confirmation: 'Подтверждение',
		filter: 'Фильтровать',
		set: 'Установить',
		error: 'Ошибка'
	},
	tracking: {
		isLoading: 'Маршрут загружается',
		routesList: {
			columns: {
				id: 'ID',
				name: 'Название',
				trackingNumber: 'Трек-номер',
				createdAt: 'Создано',
				updateAt: 'Обновлено',
			},
		},
		routeEditor: {
			id: 'ID',
			name: 'Название',
			addPoint: 'Добавить точку',
			trackingNumber: 'Трек-номер',
			createdAt: 'Дата создания',
			updatedAt: 'Дата обновления',
			placeholders: {
				name: 'Введите название маршрута',
				trackingNumber: 'Введите трек-номер',
			},
			pointsColumns: {
				name: 'Название',
				location: 'Расположение',
				createdAt: 'Дата создания',
				updatedAt: 'Дата обновления',
			},
		},
		pointEditor: {
			name: 'Название',
			location: 'Местоположение',
			createdAt: 'Дата создания',
			updatedAt: 'Дата обновления',
			icon: 'Иконка',
			done: 'Завершен?',
			placeholders: {
				name: 'Введите название точки',
				location: 'Введите местоположение',
			},
		},
		widget: {
			intro: 'Отслеживание отправления %1',
		},
	},
	calculator: {
		configurator: {
			ratesTitle: 'Ставки надбавок и тарифов',
			id: 'ID',
			rate: 'Тариф',
			name: 'Название',
			unit: 'Единица',
			amountPerUnit: 'Тариф',
			createRate: 'Создать тариф',
			editor: {
				rateSettings: 'Услуга тариф',
				name: 'Название',
				description: 'Описание',
				terms: 'Срок доставки в рабочих днях',
				unit: 'Единица',
				amountPerUnit: 'Тариф',
				createdAt: 'Создано',
				updatedAt: 'Обновлено',
				ratesByWeight: 'Тарифы по весу',
				placeholders: {
					name: 'Введите название тарифа',
					description: 'Введите описание тарифа',
					amountPerUnit: 'Введите тариф',
				},
			},
			additions: {
				fuel: 'Топливная надбавка',
				internationalVat: 'НДС Международный',
				innerVat: 'НДС Внутрироссийский',
			},
			profit: 'Маржинальная прибыль тарифа',
			rateName: 'Выбран тариф',
			columns: {
				weight: 'Вес (кг)',
				netCost: 'Действующий витринный тариф',
				grossCost: 'Новый витринный тариф',
			},
			unlistedRate: 'Послед. Кг.',
			senderCountry: 'Страна отправителя',
			senderCity: 'Город отправления',
			destinationCountry: 'Страна получателя',
			destinationCity: 'Город получателя',
			forRuRuRates: 'Для тарифов Россия - Россия',
			geographyConfiguration: {
				title: 'Управление географией',
				countryParams: 'Параметры страны',
				cityParams: 'Параметры города',
				countryCode: 'Код страны',
				countryName: 'Название страны',
				country: 'Страна',
				citiesList: 'Список городов',
				cityCode: 'Код города',
				cityName: 'Название города',
				controlButtons: {
					add: 'Добавить наименование',
					addFromList: 'Добавить списком',
					remove: 'Удалить наименование',
					clear: 'Очистить',
					find: 'Найти',
					citySearch: 'Поиск города',
					enterCityName: 'Введите название города',
					notifications: {
						cityNotFound: 'Город не найден'
					}
				}
			}
		},
		widget: {
			ratesTableTitle: 'Доступные варианты международной доставки',
			calculate: 'Расчитать',
			iDoApply: 'Подтверждаю',
			disclaimer: {
				'1': 'Тариф рассчитывается при условии, что плательщик является резидентом Российской Федерации.',
				'2': 'Объемный и фактический вес могут не совпадать: платный вес рассчитывается по большему значению.',
				'3': 'Стоимость доставки рассчитывается с учетом НДС, топливной надбавки и без учета дополнительных таможенных платежей для получателя, размер которых определяется действующим таможенным законодательством страны назначения.',
				'4': 'Фактический вес отправления не превышает 30 кг, габариты не превышают 120*50*50 см.',
				'5': 'Отправление не содержит вложимого запрещенного к международной пересылке. Со списком запрещенного содержимого Вы можете ознакомиться на нашем сайте по ссылке www.____.ru',
				'6': 'Расчет носит предварительный характер, может измениться после контрольного измерения отправления. Не является публичной офертой.',
				'7': 'Срок доставки исчисляется в рабочих днях, является ориентировочным и указывается для ознакомительных целей.',
			},
			additions: {
				fuel: 'Топливная надбавка',
				internationalVat: 'НДС Международный',
				innerVat: 'НДС Внутрироссийский',
			},
			ratesTable: {
				service: 'Услуга',
				amount: 'Стоимость',
				deliveryTerm: 'Срок доставки',
			},
			calculateShipmentPrice: 'Расчет стоимость отправление',
			hint: 'Выберите тариф и введите размеры, чтобы получить стоимость',
			selectRate: 'Выберите тариф',
			dimensionsIntro: 'Размеры отправления (см)',
			weightTitle: 'Вес отправления',
			weight: 'Вес',
			geography: 'География отправлений',
			dimensions: {
				length: 'Длина',
				width: 'Ширина',
				height: 'Высота',
			},
			volumeWeight: 'Объемный вес',
			referenceValues: 'Справочные значения',
			totalAmount: 'Итоговая стоимость',
		},
	},
	payments: {
		customer: {
			locate: 'Найти пользователя',
			foundCustomers: 'Найденные клиенты',
			noCustomers: 'По заданным критериям клиенты не найдены. Создайте клиента',
			selectedUser: 'Выбранный пользователь',
			fio: 'ФИО',
			phone: 'Телефон',
			email: 'eMail',
			selectIntro: 'Выберите получателя',
		},
		amount: 'Сумма',
		purpose: 'Назначение',
		list: 'Список платежей',
		filterByPurpose: 'Фильтровать по назначению',
		payment: {
			title: 'Выбранный платёж',
			customerCode: 'Код пользователя',
			purpose: 'Назначение платежа',
			amount: 'Сумма',
			status: 'Статус',
			createdAt: 'Создан',
			updatedAt: 'Обновлен',
			operationId: 'ID операции'
		},
		createAndSave: 'Создать и отправить платёжную ссылку',
		confirmationText: 'Вы действительно хотите создать этот платёж?'
	},
	auth: {
		login: 'Логин',
		password: 'Пароль',
		performLogin: 'Войти',
		error: 'При входе возникла ошибка. Проверьте логин и пароль и попробуйте снова'
	}
};
