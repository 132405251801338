import {useEffect, useState} from 'react';
import {useLoaderData} from 'react-router-dom';
import {RatesListLoaderResponse} from '../../../routes/calculatorRoutes';
import {Button, Col, Input, Row, Select, Table, TableColumnsType} from 'antd';
import type {BaseSelectRef} from 'rc-select';
import {City, ComplexRate, Country, Rate} from '../ratesSlice';
import {localization} from '../../../app/localization/ru';
import {getCities, getComplexRates, getRatesForRussia} from '../ratesAPI';
import {FUEL_ADDITION_NAME, INNER_VAT, INTERNATIONAL_VAT} from '../../../app/config';

/**
 * Компонент виджета калькулятора тарифов
 * 
 * @author Sergey Maksimenko
 * @since 8.01.23
 */
export const RatesWidget = () => {
	const DIVIDE_COEFFICIENT = 5000;
	const MAX_CALCULATABLE_RATE = 30;
	const UNLISTED_RATE_INDEX = 100500;
	const CHINA_CODE = 'cn';
	const RUSSIA_CODE = 'ru';
	const ALL_CHINA_TOWN_CODE = 'CHINA_TOWN';
	const COMPLEX_RATE_KIND = 'complexRate';
	const { initialRates, countries, initialSenderCities, initialDestinationCities, settings } = useLoaderData() as RatesListLoaderResponse;

	if (!(countries && initialSenderCities && initialDestinationCities && settings)) {
		return;
	}

	let settingsByKeys : {
        [key: string]: string;
     } = {};

	settingsByKeys = settings.reduce((sBK, settingObject) => {
		sBK[settingObject.code] = settingObject.value;

		return sBK;
	}, settingsByKeys);

	const fuelAddition = parseInt(settingsByKeys[FUEL_ADDITION_NAME]);
	const internationalVat = parseInt(settingsByKeys[INTERNATIONAL_VAT]);
	const innerVat = parseInt(settingsByKeys[INNER_VAT]);

	const [rates, setRates] = useState<Rate[] | ComplexRate[]>(initialRates);
	const [senderCountry, setSenderCountry] = useState<Country>();
	const [senderCities, setSenderCities] =useState<City[]>();
	const [senderCity, setSenderCity] = useState<City>();
	const [ready, setReady] = useState(false);

	const [destinationCountry, setDestinationCountry] = useState<Country>();
	const [destinationCities, setDestinationCities] = useState<City[]>();
	const [destinationCity, setDestinationCity] = useState<City>();

	const [length, setLength] = useState<number>();
	const [width, setWidth] = useState<number>();
	const [height, setHeight] = useState<number>();
	const [volumeWeight, setVolumeWeight] = useState<number>(0);

	const [weight, setWeight] = useState<number>(0);
	const [resultAmount, setResultAmount] = useState<number>(0);

	const [calculatedAmounts, setCalculatedAmounts] = useState<{
        [key: string]: number;
     }>({});

	const [approved, setApproved] = useState<boolean>(false);

	const [currentRate, setCurrentRate] = useState(rates[0]);

	let senderCityRef: BaseSelectRef | null;
	let destinationCityRef: BaseSelectRef | null;

	useEffect(() => {
		setReady(true);
	});

	const getComplexRateId = (complexRate: ComplexRate) => {
		return complexRate.rates.map((rate: Rate) => rate.id).join('_');
	};
    
	const senderCountrySelectedHandler = async (senderCountryCode: string) => {
		if (!countries) {
			return;
		}

		const selectedCountry = countries.find(country => country.code === senderCountryCode);
		if (selectedCountry) {
			setSenderCountry(selectedCountry);
			const newCities = await getCities(senderCountryCode);
			setSenderCities(newCities);

			if (destinationCountry && senderCity && destinationCity) {
				let newRates;
				if (senderCountryCode.toLocaleLowerCase() === RUSSIA_CODE && 
				destinationCountry.code.toLocaleLowerCase() === RUSSIA_CODE) {
					newRates = await getRatesForRussia(senderCity.code, destinationCity.code);				
				} else {
					const sourceCityCode = senderCountryCode === CHINA_CODE ? ALL_CHINA_TOWN_CODE : senderCity.code;
					newRates = await getComplexRates(senderCountryCode, destinationCountry.code, sourceCityCode, destinationCity.code);
				}			
				
				setRates(newRates);
			}
		}
	};

	const senderCitySelectedHandler = async (senderCityCode: string) => {
		const selectedCity = senderCities?.find(city => city.code === senderCityCode);		
		if (selectedCity) {
			setSenderCity(selectedCity);								
		}

		if (selectedCity && destinationCity && senderCountry && destinationCountry) {
			let newRates;
			if (senderCountry.code.toLocaleLowerCase() === RUSSIA_CODE && 
				destinationCountry.code.toLocaleLowerCase() === RUSSIA_CODE) {
					newRates = await getRatesForRussia(senderCityCode, destinationCity.code);				
				} else {
					const sourceCityCode = senderCountry.code === CHINA_CODE ? ALL_CHINA_TOWN_CODE : senderCityCode;
					newRates = await getComplexRates(senderCountry.code, destinationCountry.code, sourceCityCode, destinationCity.code);
				}
			setRates(newRates.map((rate: ComplexRate) => {
				rate.kind = COMPLEX_RATE_KIND;
				return rate;
			}));
		}
	};

	const destinationCountrySelectedHandler = async (destinationCountryCode: string) => {
		if (!countries) {
			return;
		}

		const selectedCountry = countries.find(country => country.code === destinationCountryCode);
		if (selectedCountry) {
			setDestinationCountry(selectedCountry);
			const newCities = await getCities(destinationCountryCode);
			setDestinationCities(newCities);

			if (senderCountry && senderCity && destinationCity) {
				let newRates;

				if (senderCountry.code.toLocaleLowerCase() === RUSSIA_CODE && 
				destinationCountryCode.toLocaleLowerCase() === RUSSIA_CODE) {
					newRates = await getRatesForRussia(senderCity.code, destinationCity.code);				
				} else {
					const sourceCityCode = senderCountry.code === CHINA_CODE ? ALL_CHINA_TOWN_CODE : senderCity.code;
					newRates = await getComplexRates(senderCountry.code, destinationCountryCode, sourceCityCode, destinationCity.code);
				}

				setRates(newRates);
			}
		}
	};

	const destinationCitySelectedHandler = async (destinationCityCode: string) => {
		const selectedCity = destinationCities?.find(city => city.code === destinationCityCode);		
		if (selectedCity) {
			setDestinationCity(selectedCity);
		}		

		if (senderCity && selectedCity && senderCountry && destinationCountry) {
			let newRates;
				if (senderCountry.code.toLocaleLowerCase() === RUSSIA_CODE && 
				destinationCountry.code.toLocaleLowerCase() === RUSSIA_CODE) {
					newRates = await getRatesForRussia(senderCity.code, destinationCityCode);				
				} else {
					const sourceCityCode = senderCountry.code === CHINA_CODE ? ALL_CHINA_TOWN_CODE : senderCity.code;
					newRates = await getComplexRates(senderCountry.code, destinationCountry.code, sourceCityCode, destinationCityCode);
				}
			setRates(newRates.map((rate: ComplexRate) => {
				rate.kind = COMPLEX_RATE_KIND;
				return rate;
			}));
		}
	};

	const orderButtonClickedHandler = async () => {
		// TODO дописать
	};

	const formatCountryAndCity = (items: Country[] | City[]) => {
		return items.map(item => ({
			key: item.code + Math.random() + '',
			value: item.code,
			label: item.name
		}));
	};

	const lengthChangedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setLength(parseFloat(value));  
	};

	const widthChangedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setWidth(parseFloat(value));
	};

	const heightChangedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setHeight(parseFloat(value));
	};

	const clearButtonClickHandler = () => {
		setLength(0);
		setWidth(0);
		setHeight(0);
		setWeight(0);
	};

	const approvedChangedHandler = (event: {target: {checked: boolean}}) => {
		setApproved(event.target.checked);
	};

	const weightChangedHandler = (event: {target: {value: string}}) => {
		setWeight(parseFloat(event.target.value));
	};

	const calculateClickedHandler = () => { 
		if (!(length && width && height)) {
			return;
		}

		const newVolumentWeight = (length * width * height) / DIVIDE_COEFFICIENT;
		setVolumeWeight(newVolumentWeight);
		
		const calculatedWeight = newVolumentWeight > weight ? newVolumentWeight : weight;        
		const calculatedAmounts:{
            [key: string]: number;
         } = {};
	
		rates.forEach(rate => {			
			let totalCost;
			if (calculatedWeight > MAX_CALCULATABLE_RATE) {
				// TODO посчитать тариф для первых 30 по тарифу 30				
				const firstPartAmount = rate.amounts.find(currentAmount => currentAmount.weight === MAX_CALCULATABLE_RATE*10);
				const firstPartDeliveryCost = firstPartAmount ? firstPartAmount.amount : 0;

				const perKgAmount = rate.amounts.find(currentAmount => currentAmount.weight === UNLISTED_RATE_INDEX);
				const additionalPartDeliveryCost = perKgAmount ? perKgAmount.amount : 0;

				const additionalWeight = calculatedWeight - MAX_CALCULATABLE_RATE;				
				totalCost = firstPartDeliveryCost + (additionalWeight * additionalPartDeliveryCost);
			} else {

				const suitableAmounts = rate.amounts
					.sort((a: {weight: number, amount: number}, b: {weight: number, amount: number}) => {
						 if (a.weight < b.weight) {
							 return -1;
						 } else if (a.weight > b.weight) {
							 return 1;
						 }

						 return 0;
					})
					.filter(amount => amount.weight >= calculatedWeight*10);
				const amount = suitableAmounts.length ? suitableAmounts[0].amount : 0;
				totalCost = amount;
			}

			// const totalWithAdditions = (100 + fuelAddition + internationalVat + innerVat) / 100;
			// договорились не учитывать надбавки при расчете
			//const totalWithAdditions = totalCost;
			totalCost = totalCost/**totalWithAdditions*//100;
			if (rate.kind === COMPLEX_RATE_KIND) {
				calculatedAmounts[getComplexRateId(rate)] = totalCost;
			} else {
				if (typeof rate.id !== 'undefined') {
					calculatedAmounts[rate.id + ''] = totalCost;
				}
			}			
		});

		setCalculatedAmounts(calculatedAmounts);
	};

    interface RateType {
        key: React.Key;
        name: string;
        amount: string;
        term: number;
    }

    interface AdditionsType {
        key: React.Key;
        name: string;
        value: number;
    }

    const rateColumns: TableColumnsType<RateType> = [
    	{
    		title: localization.calculator.widget.ratesTable.service,
    	dataIndex: 'name',
    	},
    	// eslint-disable-next-line no-mixed-spaces-and-tabs
    	{
    		title: localization.calculator.widget.ratesTable.amount,
    		dataIndex: 'amount',
    	},
    	{
    		title: localization.calculator.widget.ratesTable.deliveryTerm,
    		dataIndex: 'term',
    	},
    ]; 

    const additionsColumns: TableColumnsType<AdditionsType> = [
    	{
    		title: '',
    		dataIndex: 'name'
    	},
    	{
    		title: '',
    		dataIndex: 'value',
    		render: value => value + ' %'
    	}
    ];

    // rowSelection object indicates the need for row selection
    const rowSelection = {
    	onChange: (selectedRowKeys: React.Key[], selectedRows: RateType[]) => {    		

    		if (!selectedRowKeys || !selectedRowKeys.length) {
    			return;
    		}

    		const selectedRate = rates.find(rate => 
    			rate.kind === COMPLEX_RATE_KIND ? 
    				getComplexRateId(rate as ComplexRate) === selectedRowKeys[0] :
    				rate.id === selectedRowKeys[0]);    		
    		if (selectedRate) {
    			setCurrentRate(selectedRate);
    		}
    	},
    	getCheckboxProps: (record: RateType) => ({
    		disabled: record.name === 'Disabled User', // Column configuration not to be checked
    		name: record.name,
    	}),
    };

    const formatRatesData = (): RateType[] => {
    	if (rates.length && rates[0].kind === COMPLEX_RATE_KIND) {
    		return (rates as ComplexRate[]).map((complexRate: ComplexRate, index: number) => {
    			const complexRateId = getComplexRateId(complexRate);
    			const term = complexRate.rates.reduce((totalTerm, rate) => totalTerm + rate.terms, 0);

    			return {
    				key: complexRateId,
    				name: `Составной тариф ${index + 1}`,
    				amount: typeof calculatedAmounts[complexRateId] === 'undefined' ? '' : calculatedAmounts[complexRateId].toFixed(2),
    				term
    			};
    		});
    	}

    	return (rates as Rate[]).map((rate: Rate) => ({
    		key: rate.id + '',
    		name: rate.name,
    		amount: typeof calculatedAmounts[rate.id + ''] === 'undefined' ? '' : calculatedAmounts[rate.id + ''].toFixed(2),
    		term: rate.terms
    	}));
    };

    const formatAdditionColumnsData = (): AdditionsType[] => {
    	const additions: AdditionsType[] = [
    		{
    			key: '0',
    			name: localization.calculator.widget.additions.fuel,
    			value: fuelAddition
    		},
    		{
    			key: '1',
    			name: localization.calculator.widget.additions.internationalVat,
    			value: internationalVat
    		},
    		{
    			key: '2',
    			name: localization.calculator.widget.additions.innerVat,
    			value: innerVat
    		}
    	];

    	return additions;
    };

    const geographyTitleClass = 'text-center block mt-2 mr-14';
    const geographyBlockClass = 'ml-4 mt-2 mr-20 flex justify-center';
    const senderGeographyClass = 'mt-2';
    const countrySelectorClass = 'w-36';
    const citySelectorClass = 'w-36 ml-2';
    const destinationGeographyClass = 'mt-2 ml-32';
    const hintNameClass = 'ml-4 leading-9';
    const dimensionsNameClass = 'mt-2 text-center block mr-14';
    const weightNameClass =  'text-center block mr-14';
    const dimensionsBlockClass = 'mt-5 text-center';
    const dimenstionsRowClass = 'mt-4 justify-center';
    const volumeWeightLabelBlockClass = 'mt-2 flex text-center justify-center';
    const volumeWeightValueClass = 'ml-2';
    const weightLabelClass = 'leading-9';
    const amountLabelBlockClass = 'mt-2';
    const controlButtonClass = 'text-right mr-4 mt-2';
    const resultAmountValueClass = '';
    const disclaimerBlockClass = 'ml-4 mr-4 bg-slate-300 p-4';
    const confirmationClass = 'text-right flex ml-2';
    const inputContainerClass = 'w-full flex justify-end ml-3.5';
    const confirmationLabelClass = 'flex';
    const checkboxNameClass = 'leading-8';
    const checkboxClass = 'ml-2 mr-2';
    const weightContainerClass = 'flex justify-center ml-4 mt-2';
    const weightClass = 'mr-4 mt-2 text-left flex w-36';
    const weightInputClass = 'ml-2';
    const ratesBlockClass = 'mt-2';
    const ratesBlockNameClass = 'w-full text-center pl-6';
    
    const ratesTableClass = 'w-2/3';
    const additionsBlockClass = 'ml-4 mt-2 bg-slate-300 w-96';
    const orderButtonBlockClass = 'mt-2';
    return(<div>
    	<span className={geographyTitleClass}>
    		{ localization.calculator.widget.geography }
    	</span>
    	{/* блок географии */}
    	<div className={geographyBlockClass}>
    		{/* отправление */}
    		<div className={senderGeographyClass}>
    			{/* страна отправителя */}
    			<Select className={countrySelectorClass}						
    				onChange={senderCountrySelectedHandler}
					showSearch
					filterOption={(input, option) =>
						(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
					  }
    				options={formatCountryAndCity(countries)}/>

    			{/* город отправителя */}
    			<Select className={citySelectorClass}
    				ref={r => senderCityRef = r}    		                  
    				onChange={senderCitySelectedHandler}
					showSearch
					filterOption={(input, option) =>
						(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
					  }
    				options={formatCountryAndCity(senderCities ?? [])}/>
    		</div>

    		{/* назанчение */}
    		<div className={destinationGeographyClass}>
    			{/* страна получателя */}
    			<Select className={countrySelectorClass}    		
    				onChange={destinationCountrySelectedHandler}
					showSearch
					filterOption={(input, option) =>
						(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
					  }
    				options={formatCountryAndCity(countries)}/>

    			{/* город получателя */}
    			<Select className={citySelectorClass}
    				ref={r => destinationCityRef = r}    				
    				onChange={destinationCitySelectedHandler}
					showSearch
					filterOption={(input, option) =>
						(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
					  }
    				options={formatCountryAndCity(destinationCities ?? [])}/>
    		</div>
    	</div>

    	{/* размеры */}
    	<div className={dimensionsBlockClass}>
    		<span className={dimensionsNameClass}>
    			{ localization.calculator.widget.dimensionsIntro }
    		</span>
    		<Row className={dimenstionsRowClass}
    			style={{marginRight: '100px'}}>
    			{/* длина */}
    			<Col span={2}
    				className={hintNameClass}
    				style={{lineHeight: '30px'}}>
    				{localization.calculator.widget.dimensions.length}
    			</Col>
    			<Col span={3}>
    				<Input type="number"
    					allowClear={true}
    					min="0"
    					value={length}
    					onChange={lengthChangedHandler}
    					suffix="см"/>
    			</Col>

    			{/* ширина */} 
    			<Col span={2}
    				className={hintNameClass}
    				style={{lineHeight: '30px'}}>
    				{localization.calculator.widget.dimensions.width}
    			</Col>
    			<Col span={3}>
    				<Input type="number"
    					allowClear={true}
    					min="0"
    					value={width}
    					onChange={widthChangedHandler}
    					suffix="см"/>
    			</Col>

    			{/* высота */}
    			<Col span={2}
    				className={hintNameClass}
    				style={{lineHeight: '30px'}}>
    				{localization.calculator.widget.dimensions.height}
    			</Col>
    			<Col span={3}>
    				<Input type="number"
    					allowClear={true}
    					min="0"
    					value={height}
    					onChange={heightChangedHandler}
    					suffix="см"/>
    			</Col>
    		</Row>    
    	</div>

    	{/* Вес */}
    	<span className={weightNameClass}>
    		{ localization.calculator.widget.weightTitle }
    	</span>
    	<div className={weightContainerClass}>
    		<div className={weightClass}
    			style={{marginRight: '-27.5rem'}}>
    			<span className={weightLabelClass}>
    				{ localization.calculator.widget.weight }
    			</span>
    			<Input type="number"
    				min="0"
    				className={weightInputClass}
    				value={weight}
    				suffix="кг"
    				onChange={weightChangedHandler}/>
    		</div>
    	</div>

    	{/* индикации объемного веса */}
    	<div className={volumeWeightLabelBlockClass}>
    		{
    			volumeWeight ? 
    				<div>
    					<span>
    						{ localization.calculator.widget.volumeWeight }
    					</span>
    					<span className={volumeWeightValueClass}>
    						{ volumeWeight + ' ' + localization.common.kg } 
    					</span>
    				</div>
    				: ''
    		}
    	</div>

    	{/* дисклеймер */}
    	<div className={disclaimerBlockClass}>
    		<ol>
    			<li>
    				{ localization.calculator.widget.disclaimer[1] }
    			</li>
    			<li>
    				{ localization.calculator.widget.disclaimer[2] }
    			</li>
    			<li>
    				{ localization.calculator.widget.disclaimer[3] }
    			</li>
    			<li>
    				{ localization.calculator.widget.disclaimer[4] }
    			</li>
    			<li>
    				{ localization.calculator.widget.disclaimer[5] }
    			</li>
    			<li>
    				{ localization.calculator.widget.disclaimer[6] }
    			</li>
    			<li>
    				{ localization.calculator.widget.disclaimer[7] }
    			</li>
    		</ol>

    		<div className={confirmationClass}>
    			<div className={inputContainerClass}>
    				<label className={confirmationLabelClass}>
    					<span className={checkboxNameClass}>
    						{localization.calculator.widget.iDoApply}
    					</span>
    					<Input type="checkbox"
    						className={checkboxClass}
    						onChange={approvedChangedHandler}/>
    				</label>
    			</div>                
    			<Button disabled={!approved}
    				onClick={calculateClickedHandler}>
    				{localization.calculator.widget.calculate}
    			</Button>
    		</div>
    	</div>

    	{/* кнопки управления */}
    	<div className={controlButtonClass}>
    		<Button onClick={clearButtonClickHandler}>
    			{localization.common.clear}
    		</Button>
    	</div>

    	{/* блок доступных вариатов по тарифам */}
    	<div className={ratesBlockClass}>
    		<div className={ratesBlockNameClass}>
    			<p>{localization.calculator.widget.ratesTableTitle}</p>
    		</div>

    		{
    			rates.length ?
    				<Table
    					className={ratesTableClass}
    					rowSelection={{
    						type: 'radio',
    						...rowSelection,
    					}}
    					columns={rateColumns}
    					dataSource={formatRatesData()}/> : <p>Нет подходящих тарифов</p>
    		}
    	</div>

    	{ready ? 
    		<span>
    			<div className={ratesBlockNameClass}>
    				<p>{localization.calculator.widget.referenceValues}</p>
    			</div>
    			<Table className={additionsBlockClass}
    				columns={additionsColumns}
    				dataSource={formatAdditionColumnsData()}/>
    		</span> : ''}    

    	{/* кнопка заказа */}
    	<div className={orderButtonBlockClass}>
    		<Button type="primary"
    			onClick={orderButtonClickedHandler}>
    			{ localization.common.order }
    		</Button>
    	</div>
    </div>);
};