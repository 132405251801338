import {BASE_URL} from '../../../app/config';

export const getPayments = async (purpose?: string) => {
	let url = `${BASE_URL}/payments`;
	if (purpose) {
		url += '?purpose=' + purpose; 
	}
    
	const response = await fetch(
		url,
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);
    
	return await response.json();
};