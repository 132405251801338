import {BASE_URL} from '../../app/config';
import {POST} from '../../app/constants';

export async function performLogin(login: string, password: string) {
	const url = `${BASE_URL}/auth/login`;
    
	const response = await fetch(
		url, 
		{
			method: POST,
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({login, password})
		}
	);
    
	return await response.json();
}