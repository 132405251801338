import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { savePoint } from './pointEditorAPI';
import { Button, Checkbox, Col, Input, Row } from 'antd';
import { formatDate } from '../../../../app/utils';
import { PointEditorLoaderResponse } from '../../../../routes/trackingRoutes';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { localization } from '../../../../app/localization/ru';

/**
 * Компонент настройки единичной точки
 * 
 * @author Sergey Maksimenko
 * @since 27.12.23
 */
export const PointEditorComponent = () => {
    const navigate = useNavigate();

    const loaderResponse: PointEditorLoaderResponse = useLoaderData() as PointEditorLoaderResponse;
    const { point, routeId } = loaderResponse;
    const [updatedPoint, setUpdatedPoint] = useState(point);

    console.log('routeId from loader', routeId);
    const saveButtonClickedHandler = async () => {
        console.log('updatedPoint', updatedPoint);
        const savedPoint = await savePoint(updatedPoint);
        setUpdatedPoint(savedPoint);
        navigate(`/tracking/routes/${routeId}`);
    };

    const doneChangedHandler = (e: CheckboxChangeEvent) => {
        console.log(`point done changed ${e.target.checked}`);
        const newPoint = {
            ...updatedPoint
        };
        newPoint.done = e.target.checked;
        setUpdatedPoint(newPoint);
    };

    const cancelButtonClickedHandler = () => {
        navigate(`/tracking/routes/${routeId}`);
    };

    const pointNameChangedHandler = (event: {target: {value: string}}) => {
        const newPoint = {
            ...updatedPoint
        };

        newPoint.name = event.target.value;
        setUpdatedPoint(newPoint);
    };

    const pointLocationChangedHandler = (event: {target: {value: string}}) => {
        const newPoint = {
            ...updatedPoint
        };

        newPoint.location = event.target.value;
        setUpdatedPoint(newPoint);
    };

    const containerClass = 'p-4';
    const controlButtonClass = 'mt-2';
    const cancelButtonClass = 'ml-2';
    const rowClass = 'mt-2';
    const pointParamsClass = 'mt-2';
    return(<div className={containerClass}>
        {/* управляющие кнопки */}
        <div className={controlButtonClass}>
            {/* Сохранить */}
            <Button onClick={saveButtonClickedHandler}>
                { localization.common.save }
            </Button>

            {/* Отмена */}
            <Button className={cancelButtonClass}
                    onClick={cancelButtonClickedHandler}>
                { localization.common.cancel }
            </Button>

            {/* параметры точки */}
            <div className={pointParamsClass}>
                    {/* название */}
                    <Row className={rowClass}>
                        <Col span={4}>
                            { localization.tracking.pointEditor.name }
                        </Col>      
                        <Col span={8}>
                            <Input 
                                placeholder={localization.tracking.pointEditor.placeholders.name}
                                value={updatedPoint? updatedPoint.name : ''}
                                onChange={pointNameChangedHandler}/>
                        </Col>
                    </Row>

                    {/* местоположение */}
                    <Row className={rowClass}>
                        <Col span={4}>
                            { localization.tracking.pointEditor.location }
                        </Col>
                        <Col span={8}>
                               <Input placeholder={localization.tracking.pointEditor.placeholders.location}
                                value={updatedPoint? updatedPoint.location : ''}
                                onChange={pointLocationChangedHandler}/> 
                        </Col>
                    </Row>

                    {/* иконка */}
                    <Row className={rowClass}>
                        <Col span={4}>
                            { localization.tracking.pointEditor.icon }
                        </Col>
                        <Col span={8}>
                            {/* добавить список иконок */}
                        </Col>
                    </Row>

                    {/* пройден ли */}
                    <Row className={rowClass}>
                        <Col span={4}>
                            { localization.tracking.pointEditor.done }
                        </Col>
                        <Col span={8}>
                            <Checkbox 
                                defaultChecked={updatedPoint ? updatedPoint.done : false}
                                onChange={doneChangedHandler}>
                                { localization.common.yes }
                            </Checkbox>
                        </Col>
                    </Row>

                    {/* дата создания */}
                    <Row className={rowClass}>
                        <Col span={4}>
                            { localization.tracking.pointEditor.createdAt }
                        </Col>
                        <Col span={8}>
                            {updatedPoint && updatedPoint.createdAt ? formatDate(updatedPoint.createdAt) : ''}
                        </Col>
                    </Row>

                    {/* дата обновления */}
                    <Row className={rowClass}>
                        <Col span={4}>
                            { localization.tracking.pointEditor.updatedAt }
                        </Col>
                        <Col span={8}>
                            {updatedPoint && updatedPoint.updatedAt ? formatDate(updatedPoint.updatedAt) : ''}
                        </Col>
                    </Row>
                </div>
        </div>
    </div>);
};