import {RouteObject} from 'react-router-dom';
import React from 'react';
import {Authorization} from '../features/authorization/Authorization';

const authRoutes: RouteObject[] = [{
	path: '/auth',
	loader: async ({ request }) => {
		const url = new URL(request.url);
		const sessionId = url.searchParams.get('sessionid');
		console.log('auth loader', sessionId);
		if (sessionId) {
			//document.cookie = `JSESSIONID=${sessionId}`;
			window.location.href = 'http://localhost:5173/rates';
		}

	},
	element: <div></div>
}, {
	path: '/signin',
	element: <Authorization />
}];

export default authRoutes;