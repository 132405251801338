import { useState } from 'react';
import { Button, Col, Input, Row, Table } from 'antd';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../app/utils';
import { saveRoute } from '../../trackingAPI';
import { RouteEditorLoaderResponse } from '../../../../routes/trackingRoutes';
import { localization } from '../../../../app/localization/ru';
import { Point } from '../../trackingSlice';

/**
 * Компонент настройки единичного маршрута
 * со списком его точек
 * 
 * @author Sergey Maksimenko
 * @since 27.12.23
 */
export const RouteEditorComponent = () => {
	const navigate = useNavigate();

	const loaderResponse: RouteEditorLoaderResponse = useLoaderData() as RouteEditorLoaderResponse;

	const { route } = loaderResponse;
	const [updatedRoute, setUpdatedRoute] = useState(route);
	const [updatedPoints] = useState(route.points);

	const saveButtonClickedHandler = async () => {
		const savedRoute = await saveRoute(updatedRoute);
		setUpdatedRoute(savedRoute);
		navigate('/tracking/routes');
	};

	const addPointClickedHandler = async () => {
		if (!updatedRoute.id) { // новый маршрут
			console.log('saving updatedRoute', updatedRoute);
			const savedRoute = await saveRoute(updatedRoute);
			setUpdatedRoute(savedRoute);
		}

		navigate(`/tracking/routes/${updatedRoute.id}/points/new`);
	};

	const pointEditLinkClickHandler = (pointId: number) => () => {
		navigate(`/tracking/routes/${updatedRoute.id}/points/${pointId}`);
	};

	const cancelButtonClickedHandler = () => {
		navigate('/tracking/routes');
	};

	const prepareColumns = () => {
		return [
			{
				title: localization.tracking.routeEditor.id,
				dataIndex: 'id',
				key: 'id',
				render: (id: number) =>
					<a onClick={pointEditLinkClickHandler(id)}>
						{ id }
					</a>
			},
			{
				title: localization.tracking.routeEditor.pointsColumns.name,
				dataIndex: 'name',
				key: 'name'
			},
			{
				title: localization.tracking.routeEditor.pointsColumns.location,
				dataIndex: 'location',
				key: 'location',
			},
			{
				title: localization.tracking.routeEditor.pointsColumns.createdAt,
				dataIndex: 'createdAt',
				key: 'createdAt'
			},
			{
				title: localization.tracking.routeEditor.pointsColumns.updatedAt,
				dataIndex: 'updatedAt',
				key: 'updatedAt'
			}
		];
	};

	const routeNameChangedHandler = (event: {target: {value: string}}) => {
		const newRoute = {
			...updatedRoute
		};
		newRoute.name = event.target.value;
		setUpdatedRoute(newRoute);
	};

	const trackingNumberChangedHandler = (event: {target: {value: string}}) => {
		const newRoute = {
			...updatedRoute
		};
		newRoute.trackingNumber = event.target.value;
		setUpdatedRoute(newRoute);
	};

	const containerClass = 'p-4';  
	const controlButtonsClass = 'mt-2';
	const formRowClass = 'mt-2';
	const routerParamsClass = 'mt-4';
	const addPointButtonClass = 'ml-2';
	const cancelButtonClass = 'ml-2';
	const pointsTableClass = 'mt-2';
	const tableClass='';
	return (<div className={containerClass}>
		{/* Управляющие кнопки */}
		<div className={controlButtonsClass}>
			{/* Сохранить */}
			<Button onClick={saveButtonClickedHandler}>
				{ localization.common.save }
			</Button>

			{/* Добавить точку */}
			<Button onClick={addPointClickedHandler}
				className={addPointButtonClass}>
				{ localization.tracking.routeEditor.addPoint }
			</Button>

			{/* Отмена */}
			<Button onClick={cancelButtonClickedHandler}
				className={cancelButtonClass}>
				{ localization.common.cancel }
			</Button>
		</div>

		{/* параметры маршрута */}
		<div className={routerParamsClass}>
			{/* название */}
			<Row className={formRowClass}>
				<Col span={4}>
					{ localization.tracking.routeEditor.name }
				</Col>
				<Col span={10}>
					<Input 
						placeholder={localization.tracking.routeEditor.placeholders.name}
						value={updatedRoute.name}
						onChange={routeNameChangedHandler} />
				</Col>
			</Row>

			{/* трек-номер */}
			<Row className={formRowClass}>
				<Col span={4}>
					{ localization.tracking.routeEditor.trackingNumber }
				</Col>
				<Col span={10}>
					<Input
						placeholder={localization.tracking.routeEditor.placeholders.trackingNumber}
						value={updatedRoute.trackingNumber}
						onChange={trackingNumberChangedHandler}/>
				</Col>
			</Row>

			{/* дата создания */}
			<Row className={formRowClass}>
				<Col span={4}>
					{localization.common.createdAt}
				</Col>
				<Col span={10}>
					{updatedRoute.createdAt ? formatDate(updatedRoute.createdAt) : ''}
				</Col> 
			</Row>
			{/* дата обновления */}
			<Row className={formRowClass}>
				<Col span={4}>
					{localization.common.updatedAt}
				</Col>

				<Col span={10}>
					{updatedRoute.updatedAt ? formatDate(updatedRoute.updatedAt) : ''}
				</Col>
			</Row>
		</div>
            

		{/* таблица с точками */}
		<div className={pointsTableClass}>
			<Table
				dataSource={updatedPoints} 
				className={tableClass}
				columns={prepareColumns()} />
		</div>
	</div>);
};