import {Button, Input} from 'antd';
import {useState} from 'react';
import {localization} from '../../app/localization/ru';
import {performLogin} from './authorizationAPI';
import {AUTH_TOKEN} from "../../app/config";

/**
 * Компонент авторизации пользователя
 * 
 * @author Sergey Maksimenko
 * @since 21.02.23
 */
export const Authorization = () => {
	const [login, setLogin] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [loginError, setLoginError] = useState<boolean>(false);
   
	const loginChangedHandler = (event: {target: {value: string}}) => {
		setLoginError(false);
		if (!event.target.value) {
			return;
		}

		setLogin(event.target.value);
	};
    
	const passwordChangedHandler = (event: {target: {value: string}}) => {
		setLoginError(false);
		if (!event.target.value) {
			return;
		}

		setPassword(event.target.value);
	};

	const keyUpHandler = (event: {code: string}) => {
		if (event.code === 'Enter') {
			loginButtonClickHandler();
		}
	};
    
	const loginButtonClickHandler = async () => {
		console.log('login', login);
		console.log('password', password);
		if (!login || !password) {
			return;
		}

		const token = await performLogin(login, password);
		window.localStorage.setItem(AUTH_TOKEN, token);
		window.location.href = '/rates';
	};

	const containerBlockClass = 'mt-24 flex flex-col';
	const loginBlockClass = 'w-96 mx-auto';
	const passwordBlockClass = 'mt-2 w-96 mx-auto';
	const sendButtonContainerClass = 'mt-6 text-center';
	const errorBlockClass = 'mt-2 text-center text-red-700';
	return (
		<div>
			<div className={containerBlockClass}>
				{/* логин */}
				<label className={loginBlockClass}>
					<span>
						{localization.auth.login}
					</span>

					<Input value={login}
						onKeyUp={keyUpHandler}
						onChange={loginChangedHandler}/>
				</label>

				{/* пароль */}
				<label className={passwordBlockClass}>
					<span>
						{localization.auth.password}
					</span>

					<Input value={password}
						onKeyUp={keyUpHandler}
						onChange={passwordChangedHandler}/>
				</label>

				<div className={sendButtonContainerClass}>
					<Button onClick={loginButtonClickHandler}>
						{localization.auth.performLogin}
					</Button>
				</div>
				{loginError ?
					<span className={errorBlockClass}>
						{localization.auth.error}
					</span> : ''}
			</div>
		</div>);
};