import moment from 'moment';

export const formatDate = (date: string) => {
    return moment(date).format('DD MMMM YYYY');
}

export const replaceInTemplate = (...params: string[]) => {
    let result = params[0];
    for (let index = 1; index < params.length; index++) {
        result = result.replace(`%${index}`, params[index]);
    }

    return result;
};