import {AUTH_TOKEN, BASE_URL} from '../../../../app/config';
import { POST, PUT } from '../../../../app/constants';
import { Point } from '../../trackingSlice';

export const getPoint = async (routeId: number, pointId: number) => {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';
        
		return;
	}

	const url = `${BASE_URL}/tracking/points/by-route/${routeId}`;

	const response = await fetch(
		url,
		{
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			}
		}
	);

	if (!response.ok && response.status === 401) {
		window.location.href = '/signin';

		return null;
	}

	const points = await response.json();
	console.log('points received', points);

	return points.find((point: Point) => point.id === pointId);


};

export const savePoint = async (point: Point) => {
	const token = window.localStorage.getItem(AUTH_TOKEN);
    
	if (!token) {
		window.location.href = '/signin';
        
		return;
	}
	let url = `${BASE_URL}/tracking/points`;

	if (point.id) {
		url += '/' + point.id;
	}

	const response = await fetch(
		url,
		{
			method: point.id ? PUT : POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(point)
		}
	);

	if (!response.ok && response.status === 401) {
		window.location.href = '/signin';

		return null;
	}
    
	return await response.json();
};