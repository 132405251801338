export const BASE_URL = 'https://selfapp.tdl24.com/api/v1';

export const AUTHORIZATION_URL = 'https://selfapp.tdl24.com/api/v1/auth/auth-check';
export const AUTH_TOKEN = 'authToken';


export const FUEL_ADDITION_NAME = 'fuelAddition';
export const INTERNATIONAL_VAT = 'internationalVAT';
export const INNER_VAT = 'innerVAT';

export const MARGIN_PROFIT = 'marginProfit';