import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { ClientRouterLoaderResponse } from '../../../routes/trackingRoutes';
import { Steps } from 'antd';
import { Route, Step } from '../trackingSlice';
import { replaceInTemplate } from '../../../app/utils';
import { localization } from '../../../app/localization/ru';

/**
 * Компонент клиентского виджета маршрута
 * 
 * @author Sergey Maksimenko
 * @since 7.01.23
 */
export const RoutesWidget = () => {
    const [current, setCurrent] = useState(-1);
    const loaderResponse: ClientRouterLoaderResponse = useLoaderData() as ClientRouterLoaderResponse;
    const { route } = loaderResponse;

    console.log('route', route);

    if (!route) {
        console.log('no route');
        return (
            <div>
                Маршрут не найден
            </div>
        );
    }

    const _formatSteps = (route: Route): Step[] => {
        const results: Step[] = [];
        route.points.forEach((point, index) => {
            const result: Step = {
                title: point.name,
                description: point.location,
                subTitle: point.createdAt        
            };

            results.push(result);

            if (current === -1 && !point.done) {
                setCurrent(index);
            }
        });
    
        return results;
    };
    
    const titleBlockClass = 'text-center';
    const stepsBlockClass = 'mt-2';
    return (<div>
                {/* заголовок */}
                <div className={titleBlockClass}>
                    {replaceInTemplate(localization.tracking.widget.intro, route.trackingNumber)}
                </div>

                {/* шаги */}
                <div className={stepsBlockClass}>
                    <Steps
                        current={current}
                        items={_formatSteps(route)}
                    />
                </div>                  
    </div>);
}