import {useLoaderData} from 'react-router-dom';
import {localization} from '../../../app/localization/ru';
import {Payment} from './listSlice';
import {useState} from 'react';
import {PaymentsLoaderResponse} from '../../../routes/paymentRoutes';
import {Button, Col, Row, Table, Input} from 'antd';
import {getPayments} from './listAPI';

/**
 * Компонент списка платежей
 * 
 * @author Sergey Maksimenko
 * @since 27.12.23
 */
export const PaymentsList = () => {
	const {payments}: PaymentsLoaderResponse = useLoaderData() as PaymentsLoaderResponse;
	const [selectedPayment, setSelectedPayment] = useState<Payment>();
	const [purpose, setPurpose] = useState<string>();
	const [currentPayments, setCurrentPayments] = useState<Payment[]>(payments);

	const itemClickedHandler = (id: number) => () => {
		const selectedPayment = payments.find(payment => payment.id === id);
		setSelectedPayment(selectedPayment);
	};
	const filterClickedHandler = async () => {
		if (!purpose) {
			return;
		}

		const newPayments = await getPayments(purpose);
		setCurrentPayments(newPayments);
	};

	const purposeChangedHandler = (event: {target: {value: string}}) => {
		setPurpose(event.target.value);
	};
	
	const tableColumns = [
		{
			title: localization.payments.payment.purpose,
			dataIndex: 'purpose',
			key: 'purpose',
			render: (purpose: string, record: Payment) => <a onClick={itemClickedHandler(record.id)}>{purpose}</a>
		},
		{
			title: localization.payments.payment.customerCode,
			dataIndex: 'customerCode',
			key: 'customerCode'
		},
		{
			title: localization.payments.payment.amount,
			dataIndex: 'amount',
			key: 'amount'
		},
		{
			title: localization.payments.payment.createdAt,
			dataIndex: 'createdAt',
			key: 'createdAt'
		},
		{
			title: localization.payments.payment.status,
			dataIndex: 'status',
			key: 'status'
		}
	];

	const formatPayments = () => {
		if (!currentPayments) {
			return [];
		}

		return currentPayments.map((payment: Payment) => ({
			key: payment.id,
			...payment
		}));
	};

	const paymentsBlock = 'mt-2';
	const paymentsTitleBlock = 'text-center';
	const filterTitleBlockClass = 'text-center';
	const tableClass = 'mt-2';
	const selectedPaymentBlock = 'mt-2';
	const filterButtonBlockClass = 'mt-2 justify-center';
	const selectedPaymentTitleBlock = 'text-center';
	const selectedPaymentParamsBlock = 'mt-2 w-2/3';
	return(<div>
		<div className={paymentsBlock}>
			{/* список платежей */}
			<span className={paymentsTitleBlock}>
				{localization.payments.list}
			</span>

			<label>
				<span className={filterTitleBlockClass}>
					{localization.payments.filterByPurpose}
				</span>

				<Input value={purpose} onChange={purposeChangedHandler}/>
			</label>
			
			<div className={filterButtonBlockClass}>
				<Button onClick={filterClickedHandler}>
					{localization.common.filter}
				</Button>
			</div>

			<Table className={tableClass}
				columns={tableColumns}
				dataSource={formatPayments()}/>
		</div>

		{selectedPayment ?
			<div className={selectedPaymentBlock}>
				<span className={selectedPaymentTitleBlock}>
					{localization.payments.payment.title}
				</span>

				<div className={selectedPaymentParamsBlock}>
					<Row>
						<Col span={12}>ID</Col>
						<Col span={12}>
							{selectedPayment.id}
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							{localization.payments.payment.customerCode}
						</Col>
						<Col span={12}>
							{selectedPayment.customerCode}
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							{localization.payments.payment.purpose}
						</Col>
						<Col span={12}>
							{selectedPayment.purpose}
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							{localization.payments.payment.amount}
						</Col>
						<Col span={12}>
							{selectedPayment.amount}
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							{localization.payments.payment.status}
						</Col>
						<Col span={12}>
							{selectedPayment.status}
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							{localization.payments.payment.createdAt}
						</Col>
						<Col span={12}>
							{selectedPayment.createdAt}
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							{localization.payments.payment.updatedAt}
						</Col>
						<Col span={12}>
							{selectedPayment.updatedAt}
						</Col>
					</Row>

					<Row>
						<Col span={12}>
							{localization.payments.payment.operationId}
						</Col>
						<Col span={12}>
							{selectedPayment.operationId}
						</Col>
					</Row>
				</div>
			</div> : ''}

	</div>);

};