
import { RoutesListComponent  } from '../features/tracking/configurator/routes-list/routesList';
import { RouteEditorComponent } from '../features/tracking/configurator/route-editor/routeEditor';
import { PointEditorComponent } from '../features/tracking/configurator/point-editor/pointEditor';
import { RoutesFieldWidget } from '../features/tracking/routesFieldWidget/routesFieldWidget';
import { Point, Route } from '../features/tracking/trackingSlice';
import { getPoint } from '../features/tracking/configurator/point-editor/pointEditorAPI';
import { getPointsByRoute, getRoute, getRouteByTrackingNumber, getRoutes } from '../features/tracking/trackingAPI';
import { RouteObject } from 'react-router-dom';
import { RoutesWidget } from '../features/tracking/widget/Widget';

export interface RouteEditorLoaderResponse {
  id?: number,
  route: Route
}

export interface PointEditorLoaderResponse {
  point: Point,
  routeId?: number
}

export interface RoutesListLoaderResponse {
  routes: Route[]
}

export interface ClientRouterLoaderResponse {
  route: Route
}

const trackingRoutes: RouteObject[] = [
	{
		path: '/track-parcel',
		element: <RoutesFieldWidget />
	},
	{
		path: '/track/:trackingNumber',
		element: <RoutesWidget />,
		loader: async ({ params }) => {
			console.log('params', params);
			const route = params.trackingNumber ? await getRouteByTrackingNumber(params.trackingNumber) : '';

			const response: ClientRouterLoaderResponse = { route };

			return response;
		}
	},
	{
		path: '/tracking/routes/',
		element: <RoutesListComponent />,
		loader: async () => {
			console.log('routes loader');
			const routes = await getRoutes();

			const params: RoutesListLoaderResponse = { routes };
  
			return params;
		}
	}, {
		path: '/tracking/routes/new',
		element: <RouteEditorComponent />,
		loader: async () => {
			const emptyPoints: Point[] = [];
			const route: Route = {
				name: '',
				trackingNumber: '',
				points: emptyPoints,
			};

			const params: RouteEditorLoaderResponse = { route };
			return params;
		},
	},
	{
		path: '/tracking/routes/:routeId',
		element: <RouteEditorComponent />,
		loader: async ( { params } ) => {
			if (!params.routeId) {
				return { route: null, points: null };
			}

			const routeId = parseInt(params.routeId);
			const route: Route = await getRoute(routeId);
			route.points = await getPointsByRoute(routeId);

			return { route };
		},
	},
	{
		path: '/tracking/routes/:routeId/points/new',
		element: <PointEditorComponent />,
		loader: async({ params }) => {
			if (!params.routeId) {
				return { point: null, routeId: null };
			}

			console.log('params', params);
			const route: Route = params.routeId ? await getRoute(parseInt(params.routeId)) : null;          
  
			if (!route) {
				return { point: null, routeId: null };
			}

			const point: Point = {
				name: 'Новая точка',
				icon: 'null',
				location: '',
				order: route.points ? route.points.length : 0,
				done: false,
				routeId: route.id || 0
			};

			const response: PointEditorLoaderResponse = { 
				point, routeId: parseInt(params.routeId)
			};

			return response;
		}
	},
	{
		path: '/tracking/routes/:routeId/points/:pointId',
		element: <PointEditorComponent />,
		loader: async ({ params }) => {
			console.log('point editor loader');
			//try {
			if (!params.routeId) {
				return { point: null };
			}
            
			console.log('test 1');
			const route: Route = params.routeId ? await getRoute(parseInt(params.routeId)) : null;          

			console.log('route received', route);
  
			console.log('check 1', !route);
			console.log('check2', typeof params.pointId === 'undefined');
			if (!route || typeof params.pointId === 'undefined') {
				return { point: null, routeId: null };
			}

			console.log('requesting for point', parseInt(params.pointId));
			const point: Point = await getPoint(parseInt(params.routeId), parseInt(params.pointId));
			console.log('point received', point);
			point.routeId = params.routeId ? parseInt(params.routeId) : 0;

			console.log('new point params', params);
			const response: PointEditorLoaderResponse = { 
				point,
				routeId: route.id            
			};

			return response;
			/*} catch (error) {
            console.error(error);
            redirect('/authorize');          
          }*/
		}
	}
];

export default trackingRoutes;
  