// состояния загрузки
export const LOADING = 'loading';
export const IDLE = 'idle';
export const FAILED = 'failed';

// HTTP методы
export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const DELETE = 'delete';

// размерности веса
export const KG = 0;
export const LBS = 1;