import { RatesConfigurator } from '../features/rates-calculator/configurator/Configurator';
import {
	getCities,
	getCountries,
	getRates,
	getSettings
} from '../features/rates-calculator/ratesAPI';
import { City, Country, Rate, Setting } from '../features/rates-calculator/ratesSlice';
import { RouteObject } from 'react-router-dom';
import { RatesWidget } from '../features/rates-calculator/widget/Widget';
import {AUTH_TOKEN } from '../app/config';

export interface RatesListLoaderResponse {
    initialRates: Rate[]
    countries: Country[],
    initialSenderCities?: City[],
    initialDestinationCities?: City[],
    settings: Setting[],
	initialGeographyCities?: Map<string, City[]>
}

export interface RateEditorParams {
    params: {
        rateId: number
    }
}

export interface RateEditorLoaderResponse {
    rate: Rate
}

const calculatorRoutes: RouteObject[] = [
	{
		path: '/calculator',
		element: <RatesWidget />,
		loader: async () => {
			const countries = await getCountries();			
			const senderCities = await getCities(countries[0].code);
			const destinationCities = await getCities(countries[1].code);
			const initialRates = await getRates(countries[1].code, countries[0].code);
			const initialSenderCities = await getCities(countries[1].code);
			const initialDestinationCities = await getCities(countries[0].code);
			const settings = await getSettings();

			const response: RatesListLoaderResponse = { initialRates, countries, initialSenderCities, initialDestinationCities, settings };

			return response;
		}
	},
	{
		path: '/rates',
		element: <RatesConfigurator />,
		loader: async () => {
			const token = window.localStorage.getItem(AUTH_TOKEN);

			if (!token) {
				window.location.href  = '/signin';

				return;
			}
			const countries = await getCountries();

			const initialGeographyCities = new Map<string, City[]>();
			for (const country of countries) {
				const cities = await getCities(country.code);
				initialGeographyCities.set(country.code, cities);
			}

			let initialRates = await getRates(countries[1].code, countries[0].code);
			initialRates = initialRates.map(rate => {
				rate.amounts = rate.amounts.map(amount => {
					amount.amount = amount.amount;

					return amount;
				});

				return rate;
			});
			const settings = await getSettings();
			const initialSenderCities = await getCities(countries[1].code);
			const initialDestinationCities = await getCities(countries[0].code);

			const response: RatesListLoaderResponse = { initialRates, countries, settings, initialGeographyCities, initialSenderCities,initialDestinationCities };

			return response;
		}
	}
];

export default calculatorRoutes;