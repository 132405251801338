import {useState} from 'react';
import {Button, Col, Input, Modal, Row, Select, notification} from 'antd';

/**
 * Компонент поля трек-номера
 * @returns 
 */
export const RoutesFieldWidget = () => {
    const [trackNumber, setTrackNumber] = useState('');
    const trackNumberChangedHandler = (event: {target: {value: string}}) => {
        setTrackNumber(event.target.value);
    };

    const trackButtonClickedHandler = () => {
        window.location.replace("https://selfapp.tdl24.com/track/" + trackNumber);
    };

    const keyUpHandler = (event: {code: string}) => {
		if (event.code === 'Enter') {
			trackButtonClickedHandler();
		}
	};
    
    const containerClass = 'flex mt-2 ml-4';
    const trackNumberInputClass = '';
    const trackButtonClass = 'ml-2 mt-6';
    return (
        <div className={containerClass}>
            <label>
                <span>
                    Найти отправление:
                </span>
                <Input type="text"
                className={trackNumberInputClass}
                onKeyUp={keyUpHandler}
                onChange={trackNumberChangedHandler} />
            </label>

            <Button className={trackButtonClass}
                    onClick={trackButtonClickedHandler}>
                Найти
            </Button>
        </div>
    );
}