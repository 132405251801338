import {AUTH_TOKEN, BASE_URL} from '../../../app/config';
import { POST } from '../../../app/constants';

export async function fetchSimilarCustomers(fio: string, phone: string, email: string) {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}

	const url = `${BASE_URL}/payments/find-users`;

	const response = await fetch(
		url,
		{
			method: POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({fio, phone, email})
		}
	);
    
	return await response.json();
}

export async function createCustomer(fio: string, phone: string, email: string) {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}
	const url = `${BASE_URL}/payments/create-user`;

	const response = await fetch(
		url,
		{
			method: POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({fio, phone, email})
		}
	);

	return response.json();
}

export async function createPaymentLink(customerCode: string, purpose: string, amount: number) {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}
	const url = `${BASE_URL}/payments`;

	const response = await fetch(
		url,
		{
			method: POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({customerCode, purpose, amount})
		}
	);

	return await response.json();
}

