import {RouteObject} from 'react-router-dom';
import {PaymentConfigurator} from '../features/inline-payments/configurator/Configurator';
import {PaymentsList} from '../features/inline-payments/list/List';
import {getPayments} from '../features/inline-payments/list/listAPI';
import {Payment} from '../features/inline-payments/list/listSlice';

export interface PaymentsLoaderResponse {
    payments: Payment[]
}

const paymentRoutes: RouteObject[] = [
	{
		path: '/create-payment',
		element: <PaymentConfigurator />
	},
	{
		path: '/payments-list',
		loader: async () => {
			const payments = await getPayments();
            
			return {payments};
		},
		element: <PaymentsList></PaymentsList>
	}
];

export default paymentRoutes;