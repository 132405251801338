import { AUTH_TOKEN, BASE_URL } from '../../app/config';
import {DELETE, POST, PUT} from '../../app/constants';
import { City, Country, Rate, Setting } from './ratesSlice';

export async function getRatesForRussia(senderCityCode?: string,
	destinationCityCode?: string): Promise<Rate[]> {
		const url = `${BASE_URL}/rates/russia/${senderCityCode}/${destinationCityCode}`;
	
		const response = await fetch(
			url,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
	
		const rates = await response.json();
	
		return rates;
	
}

export async function getRates(
	senderCountryCode: string,
	destinationCountryCode: string,
	senderCityCode?: string,
	destinationCityCode?: string): Promise<Rate[]> {
	let url;

	if (senderCountryCode?.toLocaleLowerCase() === 'ru' && destinationCountryCode.toLocaleLowerCase() === 'ru') {
		url = `${BASE_URL}/rates/russia/${senderCityCode}/${destinationCityCode}`;
	} else {
		url = `${BASE_URL}/rates/${senderCountryCode}/${destinationCountryCode}`;
	}

	const response = await fetch(
		url,
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const rates = await response.json();

	return rates;
}

export async function getComplexRates(
	senderCountryCode: string,
	destinationCountryCode: string,
	senderCityCode: string,
	destinationCityCode: string
) {
	const url = `${BASE_URL}/rates/complex/${senderCountryCode}/${senderCityCode}/${destinationCountryCode}/${destinationCityCode}`;

	const response = await fetch(
		url,
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const rates = await response.json();

	return rates;	
}

export async function getRate(rateId: number): Promise<Rate | null> {
	const url = `${BASE_URL}/rates/${rateId}`;

	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';
		return null;
	}

	const response = await fetch(
		url,
		{
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			}
		}
	);


	if (response.redirected) {
		window.location.href = response.url;
	}

	if (!response.ok && response.status === 401) {
		window.location.href = '/signin';

		return null;
	}

	return await response.json();
}

export async function saveRate(rate: Rate): Promise<Rate | null> {
	if (rate.source === 'cn') {
		rate.sourceCity = 'CHINA_TOWN';
	}
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return null;
	}
	let url = `${BASE_URL}/rates`;

	if (typeof rate.id !== 'undefined') {
		url += '/' + rate.id;
	}

	rate.description = rate.description || '';

	const response: Response | null   = await fetch(
		url,
		{
			method: typeof rate.id !== 'undefined' ? PUT : POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(rate)
		});

	if (!response.ok && response.status === 401) {
		window.location.href = '/signin';

		return null;
	}

	return await response.json();
}

export async function removeRate(rateId: number): Promise<void> {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}
	let url = `${BASE_URL}/rates/${rateId}`;
	await fetch(
		url,
		{
			method: DELETE,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			}
		}		
	);
};

export async function getCountries(): Promise<Country[]> {
	const url = `${BASE_URL}/rates/countries`;

	const response = await fetch(
		url,
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const countries = await response.json();

	return countries;
}

export async function getCities(countryCode: string): Promise<City[]> {
	const url = `${BASE_URL}/rates/cities/${countryCode}`;

	const response = await fetch(
		url,
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const allCities = await response.json();

	const map = new Map<string, boolean>();
	const cities: City[] = [];
	allCities.forEach((city: City) => {
		if (!map.get(city.code)) {
			cities.push(city);
			map.set(city.code, true);
		}
	});

	return cities;
}

export async function getSettings(): Promise<Setting[]> {
	const url = `${BASE_URL}/rates/settings`;

	const response = await fetch(
		url,
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const settings = await response.json();

	return settings;
}

export async function saveSettings(settings: Setting[]) {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}
	const url = `${BASE_URL}/rates/settings`;

	const response = await fetch(
		url,
		{
			method: POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(settings)
		});

	if (!response.ok && response.status === 401) {
		window.location.href = '/signin';

		return null;
	}

	return await response.json();
}

export async function saveGeographyCountries(countries: Country[]) {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}
	const url = `${BASE_URL}/rates/geography/countries`;

	const response = await fetch(
		url,
		{
			method: POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(countries)
		});

	if (!response.ok && response.status === 401) {
		window.location.href = '/signin';

		return null;
	}

	return await response.json();
}

export async function deleteCountries(countriesIds: number[]) {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}

	const url = `${BASE_URL}/rates/geography/countries`;

	const response = await fetch(
		url,
		{
			method: DELETE,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(countriesIds)
		});

	if (!response.ok && response.status === 401) {
		window.location.href = '/signin';

		return null;
	}

	await response.json();
}

export async function deleteCities(citiesIds: number[]) {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}

	const url = `${BASE_URL}/rates/geography/cities`;

	const response = await fetch(
		url,
		{
			method: DELETE,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(citiesIds)
		});

	if (!response.ok && response.status === 401) {
		window.location.href = '/signin';

		return null;
	}

	await response.json();
}

export const sendUploadFileRequest = async (data: FormData) => {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}
	
	const response = await fetch(`${BASE_URL}/rates/geography/upload-cities-list`, {
		method: 'POST',
		headers: {
			'Authorization': 'Bearer ' + token
		},
		body: data
  });

  return await response.json();
}

// TODO старый метод, проверить использование и выпилить
export async function saveGeographyCities(cities: City[]) {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}
	const url = `${BASE_URL}/rates/geography/cities`;

	const response = await fetch(
		url,
		{
			method: POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(cities)
		});

	return await response.json();
}

export const addCounties = async (countries: Country[]) => {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}

	const url = `${BASE_URL}/rates/geography/countries`;
	const response = await fetch(
		url,
		{
			method: POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(countries)
		}
	);

	return await response.json();
};

export const addCities = async (cities: City[]) => {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}

	const url = `${BASE_URL}/rates/geography/cities`;
	const response = await fetch(
		url,
		{
			method: POST,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(cities)
		}
	);

	return await response.json();
};

export const updateCities = async (cities: City[]) => {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href + '/signin';

		return;
	}

	await fetch(`${BASE_URL}/rates/geography/cities`, {
		method: 'PUT',
		headers: {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(cities)
	});
};

export const removeCities = async (cities: City[]) => {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href + '/signin';

		return;
	}

	const citiesIds = cities.map(city => city.id);

	await fetch(`${BASE_URL}/rates/geography`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + token
		},
		body: JSON.stringify(citiesIds)
	});
};