import {AUTH_TOKEN, BASE_URL} from '../../../../app/config';
import { DELETE } from '../../../../app/constants';

export const removeRoute = async (routeId: string) => {
	const token = window.localStorage.getItem(AUTH_TOKEN);

	if (!token) {
		window.location.href = '/signin';

		return;
	}
	const url = `${BASE_URL}/tracking/routes/${routeId}`;

	const response = await fetch(
		url, {
			method: DELETE,
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-Type': 'application/json'
			}
		});

	if (!response.ok && response.status === 401) {
		window.location.href = '/signin';

		return null;
	}

	return await response.json();
};