import { useState } from 'react';
import { Button, Table } from 'antd';
import { removeRoute } from './routesListAPI';
import { Route } from '../../trackingSlice';
import { RoutesListLoaderResponse } from '../../../../routes/trackingRoutes';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { localization } from '../../../../app/localization/ru';

/**
 * Компонент настройки маршрутов для трекинга
 * 
 * @author Sergey Maksimenko
 * @since 27.12.23
*/
export const RoutesListComponent = () => {
	console.log('RoutesListComponent');
	const loaderResponse: RoutesListLoaderResponse = useLoaderData() as RoutesListLoaderResponse;
	const navigate = useNavigate();

	const { routes } = loaderResponse;
  
	const emptyKeys: React.Key[] = [];
	const [selectedRowsKeys, setSelectedRowsKeys] = useState(emptyKeys);
	const [updatedRoutes, setUpdatedRoutes] = useState(routes);

	const prepareColumns = () => {
		const columns = [
			{
				title: localization.tracking.routesList.columns.id,
				dataIndex: 'id',
				key: 'id',
				render: (id: number) => 
					<a onClick={routeEditLinkClickHandler(id)}>
						{ id }
					</a>
			},
			{
				title: localization.tracking.routesList.columns.name,
				dataIndex: 'name',
				key: 'name'    
			},
			{
				title: localization.tracking.routesList.columns.trackingNumber,
				dataIndex: 'trackingNumber',
				key: 'trackingNumber'
			},
			{
				title: localization.tracking.routesList.columns.createdAt,
				dataIndex: 'createdAt',
				key: 'createdAt'
			},
			{
				title: localization.tracking.routesList.columns.updateAt,
				dataIndex: 'updatedAt',
				key: 'updatedAt'
			}
		];

		return columns;
	};

	const createRateClickHandler = () => {
		navigate('/tracking/routes/new');  
	};

	const routeEditLinkClickHandler = (routeId: number) => () => {
		navigate(`/tracking/routes/${routeId}`);
	};

	const removeRateClickHandler = async () => {
		if (!(selectedRowsKeys && selectedRowsKeys.length)) {
			return;
		}

		for (const key of selectedRowsKeys) {
			// TODO переписать на вызов одного метода
			const routesLeft = await removeRoute(key + '');
			setUpdatedRoutes(routesLeft);
		}

		// TODO показать нотификацию, что маршрут успешно удалён
	};

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[]/*, selectedRows: DataType[]*/) => {
			// console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
			setSelectedRowsKeys(selectedRowKeys);
		}/*,
        getCheckboxProps: (record: DataType) => ({
          disabled: record.name === 'Disabled User', // Column configuration not to be checked
          name: record.name,
        })*/,
	};

	const _formatRoutes = (rawRoutes: Route[]) => {
		const result = rawRoutes.map(route => ({
			key: route.id + '',
			...route
		}));
      
		return result;
	};

	const buttonBlockClass = 'mt-2 p-4';
	const removeButtonClass = 'ml-4';
	const tableClass = 'mt-2 px-4';
	return (<div>
		{/* управляющие кнопки */}
		<div className={buttonBlockClass}>
			{/* Создать */}
			<Button onClick={createRateClickHandler}>
				{ localization.common.create }
			</Button>
            
			{/* Удалить */}
			<Button onClick={removeRateClickHandler}
					className={removeButtonClass}>
				{ localization.common.remove }
			</Button>
		</div>
            

		{/* Таблица с тарифами */}
		<Table 
			dataSource={_formatRoutes(updatedRoutes)}
			rowSelection={rowSelection}
			className={tableClass}
			columns={prepareColumns()} />
	</div>);
};