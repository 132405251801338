import { useState, useMemo, ChangeEvent } from 'react';
import { useLoaderData } from 'react-router-dom';
import {Button, Col, Input, Modal, Row, Select, notification} from 'antd';
import { RatesListLoaderResponse } from '../../../routes/calculatorRoutes';
import { localization } from '../../../app/localization/ru';
import {City, Country, Rate, Setting} from '../ratesSlice';
import { KG } from '../../../app/constants';
import {
	deleteCities,
	deleteCountries,
	getCities,
	getRates,
	addCities,
	saveGeographyCountries,
	saveRate,
	saveSettings,
	sendUploadFileRequest,
	updateCities,
	removeRate, addCounties
} from '../ratesAPI';
import { FUEL_ADDITION_NAME, INNER_VAT, INTERNATIONAL_VAT, MARGIN_PROFIT } from '../../../app/config';
import './configurator.css';

/**
 * Компонент настройки тарифов для калькулятора
 * 
 * @author Sergey Maksimenko
 * @since 14.12.23
 */
export const RatesConfigurator = () => {
	console.log('RatesConfigurator');
	const loaderResponse: RatesListLoaderResponse = useLoaderData() as RatesListLoaderResponse;

	const RU_COUNTRY_CODE = 'ru';
	const UNLISTED_RATE_INDEX = 100500;
	const DEFAULT_TERM = 10;

	const [api, contextHolder] = notification.useNotification();

	const {
		initialRates,
		countries,
		settings,
		initialSenderCities,
		initialDestinationCities,
		initialGeographyCities
	} = loaderResponse;

	let initialSettingsByKeys : {
        [key: string]: string;
     } = {};

	initialSettingsByKeys = settings.reduce((sBK, settingObject) => {
		sBK[settingObject.code] = settingObject.value;

		return sBK;
	}, initialSettingsByKeys);
    
	const [settingsByKeys, setSettingsByKeys] = useState(initialSettingsByKeys);
	const [fuelAddition, setFuelAddition] = useState<number>(parseInt(settingsByKeys[FUEL_ADDITION_NAME]));
	const [innerVAT, setInnerVAT] = useState<number>(parseInt(settingsByKeys[INNER_VAT]));
	const [internationalVAT, setInternationalVAT] = useState<number>(parseInt(settingsByKeys[INTERNATIONAL_VAT]));

	const [rateName, setRateName] = useState<string>();
	const [rateNameDisabled, setRateNameDisabled] = useState<boolean>(false);
	const [rateDescription, setRateDescription] = useState<string>();
	const [rateDescriptionDisabled, setRateDescriptionDisabled] = useState<boolean>(false);
	const [rateTerms, setRateTerms] = useState<number>();

	const [selectedRate, setSelectedRate] = useState<Rate>();
	const [rates, setRates] = useState<Rate[]>(initialRates);

	const [senderCountry, setSenderCountry] = useState<Country>(countries[1]);
	const [senderCity, setSenderCity] = useState<City>();
	const [destinationCity, setDestinationCity] = useState<City>();
	const [senderCities, setSenderCities] = useState<City[] | undefined>(initialSenderCities);
	const [destinationCities, setDestinationCities] = useState<City[] | undefined>(initialDestinationCities);

	const [destinationCountry, setDestinationCountry] = useState<Country>(countries[0]);

	// вес для целого числа считается в граммах
	const [newWeightToNetCost, setNewWeightToNetCost] = useState<{[key: string]: number}>({});
	const [weightToNetCost, setWeightToNetCost] = useState<{[key: string]: number}>({});
	const [geographyCountries, setGeographyCountries] = useState<Country[]>(countries);
	const [geographyCountry, setGeographyCountry] = useState<Country>(countries[0]);
	const [countriesToRemove, setCountriesToRemove] = useState<number[]>([]);
	const [geographyCities, setGeographyCities] = useState<Map<string, City[]>>(initialGeographyCities || new Map<string, City[]>());

	const [geographyCity, setGeographyCity] = useState<City | null>();
	const [searchModalIsOpen, setSearchModalIsOpen] = useState<boolean>();
	const [citySearchRequest, setCitySearchRequest] = useState<string>();
	const [geographyControlsDisabled, setGeographyControlsDisabled] = useState<boolean>();

	const [geographyBlockEnabled, setGeographyBlockEnabled] = useState<boolean>(false);
	const [citiesToAdd, setCitiesToAdd] = useState<City[] | null>();
	const [citiesToModify, setCitiesToModify] = useState<City[] | null>();
	const [citiesToRemove, setCitiesToRemove] = useState<number[] | null>();
	const [ratesSelectorValue, setRatesSelectorValue] = useState<number | null>();
	const [removeButtonBlocked, setRemoveButtonBlocked] = useState<boolean>();

	const _rateSelectedHandler = async (rateId: unknown, createdRate?: Rate) => {
		setRateNameDisabled(true);
		setRateDescriptionDisabled(true);
		if (typeof rateId === 'undefined' || !rateId || rateId == -1) {			
			await createEmptyRate();
			return;
		}

		
		const selectedRate = createdRate || rates.find(rate => rate.id === rateId);

		if (!selectedRate) {
			return;
		}
        
		setRateName(selectedRate.name);
		setRateDescription(selectedRate.description);
		setRateTerms(selectedRate.terms);

		const weightToNetCostCopy: {[key: string]: number} = {};
		selectedRate.amounts = selectedRate.amounts || [];
		if (selectedRate.sourceCity && senderCities) {
			const foundCity: City | undefined = senderCities.find(city => city.code === selectedRate.sourceCity);			
			if (foundCity) {
				setSenderCity(foundCity);
			}
		}


		if (selectedRate.destinationCity && destinationCities) {
			const foundCity: City | undefined = destinationCities.find(city => city.code === selectedRate.destinationCity);
			if (foundCity) {
				setDestinationCity(foundCity);
			}
		}
		
		for (let i = 1; i <= 61; i++) {
			const weight = i * 0.5;
			const isUnlistedRate = i === 61;

			const weightIndex = isUnlistedRate ? UNLISTED_RATE_INDEX : weight;
			const alreadySetAmount = selectedRate.amounts.find(amount => amount.weight === weightIndex);			

			if (alreadySetAmount) {				
				weightToNetCostCopy[weightIndex] = alreadySetAmount.amount/100;
			} else {
				if (weightIndex !== UNLISTED_RATE_INDEX || !selectedRate.amounts.find(amount => amount.weight === UNLISTED_RATE_INDEX)) {
					selectedRate.amounts.push({
						weight: weightIndex,
						amount: 0
					});
				}  
			}            
		}
		

		setWeightToNetCost(weightToNetCostCopy);
    
		setSelectedRate(selectedRate);

		setRateNameDisabled(false);
		setRateDescriptionDisabled(false);
	};

	const rateSelectedHandler = async (rateId: unknown) => {
		setRatesSelectorValue(rateId as number);
		_rateSelectedHandler(rateId);
	};

	const removeRateButtonClicked = async () => {
		if (!selectedRate || !(selectedRate.id || selectedRate.id === 0)) {
			return;
		}
		setRemoveButtonBlocked(true);

		setRatesSelectorValue(null);
		await removeRate(selectedRate.id);
		setRateName('');
		setRateDescription('');

		const newRates = await getRates(senderCountry.code, destinationCountry.code);
		setRates(newRates);
		setRemoveButtonBlocked(false);
	};

	const rateNameChangedHandler = (event: {target: {value: string}}) => {
		setRateName(event?.target.value);
	};

	const rateDescriptionChangedHandler = (event: {target: {value: string}}) => {
		setRateDescription(event?.target.value);
	};

	const rateTermsChangedHandler = (event: {target: {value: string}}) => {
		const term = parseInt(event?.target.value);

		console.log('term', term);
		/*if (isNaN(term)) {		
			setRateTerms(0);
		}*/

		console.log('setting term');	
			setRateTerms(term);
	};

	const fuelAdditionChangedHandler = (event: {target: {value: string}}) => {
		setFuelAddition(parseInt(event?.target.value));
		settingsByKeys[FUEL_ADDITION_NAME] = event?.target.value;
		setSettingsByKeys(settingsByKeys);
	};

	const innerVATChangedHandler = (event: {target: {value: string}}) => {
		setInnerVAT(parseInt(event?.target.value));
		settingsByKeys[INNER_VAT] = event?.target.value;
		setSettingsByKeys(settingsByKeys);
	};

	const internationVATChangedHandler = (event: {target: {value: string}}) => {
		setInternationalVAT(parseInt(event?.target.value));
		settingsByKeys[INTERNATIONAL_VAT] = event?.target.value;
		setSettingsByKeys(settingsByKeys);
	};

	if (!selectedRate && rates.length) {
		rateSelectedHandler(rates[0].id);
	}

	const createEmptyRate = async () => {
		const emptyRate: Rate = {
			kind: 'rate',
			name: 'Новый тариф',
			description: 'Описание нового тарифа',
			terms: DEFAULT_TERM,
			unit: KG,
			amounts: [],   
			source: senderCountry.code,
			sourceCity: senderCity? senderCity.code : '',
			destination: destinationCountry.code,
			destinationCity: destinationCity ? destinationCity.code : ''      
		};

		const createdRate = await saveRate(emptyRate);
		const newRates = await getRates(senderCountry.code, destinationCountry.code);
		if (createdRate) {
			_rateSelectedHandler(createdRate.id, createdRate);
		}
	};

	const formatRates = () => {
		const formattedRates = rates.map(rate => ({
			value: rate.id,
			label: rate.name
		}));

		formattedRates.push({
			value: -1,
			label: localization.calculator.configurator.createRate
		});

		return formattedRates;
	};

	const formattedRates = useMemo(formatRates, [rates]);

	const saveAdditionsClickHandler = async () => {		
		const newSettings: Setting[] = Object.entries(settingsByKeys).map((entry: string[]) => ({
			code: entry[0],
			value: entry[1]
		}));
		
		await saveSettings(newSettings);
	};

	const applyButtonClickedHandler = async () => {
		if (!selectedRate) {
			return Promise.resolve();
		}

		setRateNameDisabled(true);
		setRateDescriptionDisabled(true);

		const newRate = {...selectedRate};		
		if (senderCountry) {
			newRate.source = senderCountry.code;
		}
		
		if (destinationCountry) {
			newRate.destination = destinationCountry.code;
		}
		
 		if (senderCity) {
			newRate.sourceCity = senderCity.code;
		}

		if (destinationCity) {			
			newRate.destinationCity = destinationCity.code;
		}
		newRate.name = rateName || newRate.name;
		newRate.description = rateDescription || newRate.description || '';
		newRate.terms = rateTerms || newRate.terms || DEFAULT_TERM;		

		newRate.amounts = [];
		for (let i = 1; i <= 61; i++) {
			const displayWeight = i * 0.5;

			const isUnlistedRate = i === 61;

			const savedWeight = isUnlistedRate ? UNLISTED_RATE_INDEX : displayWeight;
			
			newRate.amounts.push({
				weight: savedWeight * 10,
				amount: newWeightToNetCost[savedWeight]*100 || weightToNetCost[savedWeight]*100 || 0
			});
		}

		newRate.unit = 0;
		await saveRate(newRate);

		setRateNameDisabled(false);
		setRateDescriptionDisabled(false);
	};
	
	const formatGeoItem = (items: Country[] | City[]) => {
		return items.map(item => ({
			label: item.name,
			value: item.code
		}));
	};

	const senderCountrySelectedHandler = async (countryCode: string) => {
		const selectedCountry: Country | undefined = countries.find(country => country.code === countryCode);
		if (!selectedCountry) {
			return;
		}

		setSenderCountry(selectedCountry);
		const newSenderCities = await getCities(selectedCountry.code);
		const codeToCity = new Map<string, City>();
		newSenderCities.forEach(city => {
			codeToCity.set(city.code, city);
		});
		
		setSenderCities(Array.from(codeToCity, ([name, value]) => value));
		const newRates = await getRates(countryCode, destinationCountry.code);
		setRates(newRates);
		setRemoveButtonBlocked(false);
	};

	const senderCitySelectedHandler = (cityCode: string) => {
		if (!senderCities) {
			return;
		}
		const selectedCity: City | undefined = senderCities.find(city => city.code === cityCode);
		if (!selectedCity) {
			return;
		}

		setSenderCity(selectedCity);
	};

	const destinationCountrySelectedHandler = async (countryCode: string) => {
		const selectedCountry: Country | undefined = countries.find(country => country.code === countryCode);
		if (!selectedCountry) {
			return;
		}

		setDestinationCountry(selectedCountry);
		const newDestinationCities = await getCities(selectedCountry.code);
		setDestinationCities(newDestinationCities);
		const newRates = await getRates(senderCountry.code, countryCode);
		setRates(newRates);
		setRemoveButtonBlocked(false);
	};

	const geographyCountrySelectedHandler = async (countryCode: string) => {		
		const selectedCountry: Country | undefined = countries.find(country => country.code === countryCode);
		if (!selectedCountry) {
			return;
		}

		setGeographyCountry(selectedCountry);
		const cities = await getCities(countryCode);
		const updatedGeographyCities = new Map<string, City[]>(geographyCities);
		updatedGeographyCities.set(countryCode, cities);
		setGeographyCities(updatedGeographyCities);
	};

	const destinationCitySelectedHandler = (cityCode: string) => {
		if (!destinationCities) {
			return;
		}
		const selectedCity: City | undefined = destinationCities.find(city => city.code === cityCode);
		if (!selectedCity) {
			return;
		}

		setDestinationCity(selectedCity);
	};

	const netWeightSetHandler = (weight: number) => (event: {target: {value: string}}) => {
		const netCost = parseFloat(event.target.value);
		const newWeightToNetCostCopy = {...newWeightToNetCost};
		newWeightToNetCostCopy[weight] = netCost;
		setNewWeightToNetCost(newWeightToNetCostCopy);
	};

	const geographyCitySelectedHandler = (cityCode: string) => () => {		
		const selectedCity = (geographyCities.get(geographyCountry.code) || []).find(city => city.code === cityCode);		
		if (!selectedCity) {
			return;
		}

		setGeographyCity(selectedCity);
	};

	const geographyCountryCodeChangedHandler = (event: {target: {value: string}}) => {
		const newCode: string = event?.target.value;

		const updatedGeographyCountries: Country[] = geographyCountries
			.map((country: Country) => {
				if (country.code === geographyCountry.code) {
					country.code = newCode;
				}

				return country;
			});
		const updatedGeographyCountry = {
			...geographyCountry
		};
		updatedGeographyCountry.code = newCode;
		setGeographyCountry(updatedGeographyCountry);
		setGeographyCountries(updatedGeographyCountries);

	};

	const geographyCountryNameChangedHandler = (event: {target: {value: string}}) => {
		const newName = event?.target.value;

		const updatedGeographyCountries = geographyCountries
			.map(country => {
				if (country.code === geographyCountry.code) {
					country.name = newName;
				}

				return country;
			});
		const updatedGeographyCountry = {
			...geographyCountry
		};
		updatedGeographyCountry.name = newName;
		setGeographyCountry(updatedGeographyCountry);
		setGeographyCountries(updatedGeographyCountries);
	};

	const geographyCityCodeChangedHandler = (event: {target: {value: string}}) => {
		if (!geographyCity) {
			return;
		}

		const newCode: string = event?.target.value;

		const updatedGeographyCitiesForCountry: City[] = (geographyCities.get(geographyCountry.code) || [])
			.map((city: City) => {
				if (geographyCity && city.code === geographyCity.code) {
					city.code = newCode;
				}

				return city;
			});

		// добавили новый город
		if (!updatedGeographyCitiesForCountry.length) {
			updatedGeographyCitiesForCountry.push({
				...geographyCity,
				code: newCode
			});
		}

		const updatedGeographyCity: City = {
			...geographyCity
		};

		updatedGeographyCity.code = newCode;
		setGeographyCity(updatedGeographyCity);
		const updatedGeographyCities = new Map<string, City[]>(geographyCities);
		updatedGeographyCities.set(geographyCountry.code, updatedGeographyCitiesForCountry);
		setGeographyCities(updatedGeographyCities);
	};

	const geographyCityNameChangedHandler = (event: {target: {value: string}}) => {
		if (!geographyCity) {
			return;
		}

		const newName: string = event?.target.value;

		const updatedGeographyCitiesForCountry: City[] = (geographyCities.get(geographyCountry.code) || [])
			.map((city: City) => {
				if (geographyCity && city.code === geographyCity.code) {
					city.name = newName;
				}

				return city;
			});

		// добавили новый город
		if (!updatedGeographyCitiesForCountry.length) {
			updatedGeographyCitiesForCountry.push({
				...geographyCity,
				name: newName
			});
		}

		const updatedGeographyCity: City = {
			...geographyCity
		};
		updatedGeographyCity.name = newName;
		setGeographyCity(updatedGeographyCity);
		const updatedGeographyCities = new Map<string, City[]>(geographyCities);
		updatedGeographyCities.set(geographyCountry.code, updatedGeographyCitiesForCountry);
		setGeographyCities(updatedGeographyCities);
	};

	const cityApplyButtonClickedHandler = async () => {
		if (!geographyCity) {
			return;
		}
		
		if (geographyCity.id || geographyCity.id?.toString() === '0') {
			if (!citiesToModify?.find(city => city.id === geographyCity.id)) {				
				const newCitiesModify = citiesToModify ?? [];
				const cityIsAlreadyQueriedToModify = newCitiesModify.includes(geographyCity);
				if (cityIsAlreadyQueriedToModify) {
					return;
				}
		
				newCitiesModify.push(geographyCity);
				setCitiesToModify(newCitiesModify);
			} 
		} else {			
			if (!citiesToAdd?.find(city => city.code === geographyCity.code)) {				
				const newCitiesToAdd = citiesToAdd ?? [];
				newCitiesToAdd.push(geographyCity);
				setCitiesToAdd(newCitiesToAdd);
			}
		}

		setGeographyCity(null);
	};

	const cityCancelButtonClickedHandler = () => {
		setGeographyCity(null);
	};

	const geographyApplyClickedHandler = async () => {
		setGeographyControlsDisabled(true);
		await saveGeographyCountries(geographyCountries);

		await deleteCountries(countriesToRemove);		
		if (citiesToAdd) {
			await addCities(citiesToAdd);
			setCitiesToAdd(null);
		}

		if (citiesToModify) {
			await updateCities(citiesToModify);
			setCitiesToModify(null);
		}
			
		if (citiesToRemove) {
			await deleteCities(citiesToRemove);
			setCitiesToRemove(null);
			
		}
	
		setCountriesToRemove([]);
		setGeographyControlsDisabled(false);
	};

	const addCityButtonClickedHandler = () => {
		if (!geographyCountry) {
			return;
		}

		const cities = geographyCities.get(geographyCountry.code);
		if (!cities) {
			return;
		}

		const newCity: City = {
			code: 'newCity',
			name: 'Новый город',
			countryCode: geographyCountry.code
		};
		cities.push(newCity);

		setGeographyCity(newCity);	
	};

	const addFromListButtonClickHandler = () => {
		const fileInput = document.getElementById('cities-list-input');
		if (!fileInput) {
			return;
		}

		fileInput.click();
	};

	const uploadCitesList = async (event: ChangeEvent<HTMLInputElement>) => {
		const fileInput: HTMLInputElement = document.getElementById('cities-list-input') as HTMLInputElement;
		if (!fileInput || !fileInput.files) {
			return;
		}

		// TODO добавить проверку, чтобы добавлялись только уникальные города		
		const data = new FormData();		
		data.append('file', fileInput.files[0]);

		await sendUploadFileRequest(data);
	};

	const removeCityButtonClickHandler = () => {		
		if (!geographyCountry || !geographyCity) {
			return;
		}

		const cities = geographyCities.get(geographyCountry.code);
		if (!cities) {
			return;
		}

		
		const cityToRemove = cities.find(city => city.code === geographyCity.code);
		if (cityToRemove && (cityToRemove.id || cityToRemove.id === '0')) {
			const updatedCitiesToRemove = [...(citiesToRemove ?? []), parseInt(cityToRemove.id)];
			setCitiesToRemove(updatedCitiesToRemove);
		}

		const updatedCities = cities.filter(city => city.code !== geographyCity.code);
		const updatedGeographyCities = new Map<string, City[]>(geographyCities);
		updatedGeographyCities.set(geographyCountry.code, updatedCities);
		setGeographyCity(undefined);
		setGeographyCities(updatedGeographyCities);
	};

	const clearButtonClickHandler = () => {
		setGeographyCity(undefined);
	};

	const findCityButtonClickHandler = () => {
		setSearchModalIsOpen(true);
	};

	const searchModalIsClosedHandler = () => {
		setSearchModalIsOpen(false);
		setCitySearchRequest(undefined);
	};

	const citySearchRequestChangedHandler = (event: {target: {value: string}}) => {
		setCitySearchRequest(event?.target.value);
	};

	const performCitySearch = () => {	
		if (!geographyCountry || !citySearchRequest || !citySearchRequest.length) {
			return;
		}

		const cities = geographyCities.get(geographyCountry.code);

		if (!cities) {
			return;
		}

		const city = cities.find(city =>
			city.name.includes(citySearchRequest));

		if (!city) {
			api.info({
				message: localization.common.error,
				description:
					<p>
						{ localization.calculator.configurator.geographyConfiguration.controlButtons.notifications.cityNotFound }
					</p>,
				placement: 'topRight'
			});
			return;
		}

		geographyCitySelectedHandler(city.code)();
		setCitySearchRequest(undefined);
		setSearchModalIsOpen(false);
	};

	const isRuRuRate = (): boolean => {
		if (!senderCountry || !destinationCountry) {
			return false;
		}

		return senderCountry.code === RU_COUNTRY_CODE && destinationCountry.code === RU_COUNTRY_CODE;
	};

	const addCountryButtonClickedHandler = () => {
		if (!countries) {
			return;
		}

		const newCountry: Country = {
			code: 'newCountry',
			name: 'Новая страна'
		};
		countries.push(newCountry);

		setGeographyCountry(newCountry);
	};

	const applyCountriesClickedHandler = async () => {
		await addCounties([geographyCountry]);
	};

	const removeCountryButtonClickedHandler = async () => {
		if (!geographyCountry) {
			return;
		}

		const countryToRemove = countries.find(country => country.code === geographyCountry.code);
		if (countryToRemove && (countryToRemove.id || countryToRemove.id?.toString() === '0')) {
			await deleteCountries([parseInt(countryToRemove.id)]);
			const updatedCountriesToRemove = [... (countriesToRemove ?? []), parseInt(countryToRemove.id)];
			setCountriesToRemove(updatedCountriesToRemove);
		}

		const updatedCountries = countries.filter(country => country.code !== geographyCountry.code);
		setGeographyCountries(updatedCountries);
	};

	const ratesTitleClass = 'mt-2 mb-2 text-center font-bold';
	const ratesEditorClass = 'text-center mt-2 mb-2 font-bold';
	const additionsClass = 'p-4 mx-4 border border-solid';
	const additionalFuelValueClass = 'mt-4';
	const innerVATValueClass = 'mt-4';
	const internationalVATValueClass = 'mt-4';
	const saveAdditionsButtonBlock = 'mt-4 text-right';
	const ratesAndGeographyBlock = 'flex mt-2 ml-4 justify-start';
	const rateParamsContainerBlockClass = 'mx-4 border border-solid mb-2';
	const countriesBlockClass = 'mt-2 text-center leading-loose flex flex-col';
	const senderCountrySelectClass = 'ml-2';
	const senderCityBlockClass = 'ml-4';
	const ruruSenderLabelClass = 'mt-2 text-left';
	const senderCitySelectClass = 'ml-2';
	const destinationCountryBlockClass = '';
	const destinationCountrySelectClass = 'ml-4 mb-4';
	const destinationCityBlockClass = 'ml-4';
	const destinationCitySelectClass = 'ml-2';
	const ruruDestinationLabelClass = 'mt-2 text-left';
	const ratesBlockClass = 'mt-2 text-center';
	const ratesSelectorClass = 'w-80';
	const ratesAmountsBlockClass = 'mt-2 text-center border border-solid mx-4';
	const ratesBlockCellClass = 'border border-solid';
	const removeRateButtonBlock = 'mx-4 mt-4';
	const rateNameAndDescriptionClass = 'mt-4 mx-4';
	const selectedRateBlockClass = 'ml-4';
	const rateParamsClass = 'mt-2 mb-4';
	const termsBlockClass = 'mt-4';
	const termsInputClass = '';
	const selectedRateDescriptionBlockClass = 'ml-4 mt-4';
	const controlButtonsClass = 'mb-2 mt-2 mx-4 text-right';
	const netWeightInputClass='border-0';
	const ratesByWeightClass='text-center mt-2 mb-2 font-bold mx-4';
	const geographyCountriesClass = '';
	const citiesSelectClass = 'mt-2 border border-solid p-2';
	const cityOptionClass = 'border-2 border-solid';
	const removeCountryButtonClass = '';
	const geographyConfigurationContriesControlButtons = '';
	const addCountryButtonClass = '';
	const geographyButtonsClass = 'flex flex-col';
	const cityButtonClass = 'mb-2';
	const geographyCountryEditorClass = 'mt-2 mx-4 border border-solid';
	const countryNameClass = 'text-center font-bold mb-1';
	const geographyCountryEditorContainerClass = 'flex pb-2';
	const geographyCountryEditorCodeClass = 'mx-auto';
	const countryNameEditorTitle = 'text-center mt-1 mt-2';
	const geographyCountryEditorNameClass = 'mx-auto';
	const geographyCityEditorClass = 'mt-2 border border-solid mx-4';
	const geographyCityEditorContainerClass = 'flex pb-2';
	const cityEditConfirmBlockClass = 'p-4 flex justify-ending';
	const cityApplyButtonClass = 'mr-2';
	const cityNameClass = 'text-center font-bold mb-1';
	const geographyConfigurationTitleClass = 'text-center font-bold mb-2 mt-4';
	const geographyConfigurationContainerClass = 'border border-solid mx-4 mb-8';
	const geographyConfiguratorCitiesCountryClass = 'flex justify-start ml-4 mt-2 mx-auto';
	const countryLabelClass = 'mr-4';
	const citiesListAndControlButtonsBlockClass = 'mt-2 flex mx-4';
	const geographyCitiesListBlock = 'flex flex-col mr-auto';
	const geographyCityCodeBlockClass = 'mx-auto';
	const geographyCityNameBlockClass = 'mx-auto';
	const geographyApplyButtonClass = 'mt-4 mx-4 mb-4 text-right';
	return (<div>
		<div className={ratesTitleClass}>
			{localization.calculator.configurator.ratesTitle}
		</div>

		{/* ставки надбавок и налогов */}
		<div className={additionsClass}>
			{/* Топливная надбавка */}
			<div className={additionalFuelValueClass}>
				<label>
					<span>
						{localization.calculator.configurator.additions.fuel}
					</span>
					<Input type="number"
						value={fuelAddition}
						onChange={fuelAdditionChangedHandler}
						suffix="%"/>
				</label>
			</div>


			{/* НДС Внутрироссийский */}
			<div className={innerVATValueClass}>
				<label>
					<span>
						{localization.calculator.configurator.additions.innerVat}
					</span>
					<Input type="number"
						value={innerVAT}
						onChange={innerVATChangedHandler}
						suffix="%"
					/>
				</label>
			</div>

			{/* НДС международный */}
			<div className={internationalVATValueClass}>
				<label>
					<span>
						{localization.calculator.configurator.additions.internationalVat}
					</span>
					<Input type="number"
						value={internationalVAT}
						onChange={internationVATChangedHandler}
						suffix="%"
					/>
				</label>
			</div>

			{/* кнопка "Установить" */}
			<div className={saveAdditionsButtonBlock}>
				<Button type="default"
					onClick={saveAdditionsClickHandler}>
					{localization.common.set}
				</Button>
			</div>
		</div>

		<div className={ratesEditorClass}>
			{localization.calculator.configurator.editor.rateSettings}
		</div>

		<div className={rateParamsContainerBlockClass}>
			{/* Селектор тарифов */}
			<div className={ratesAndGeographyBlock}>
				{/* услуга-тариф */}
				<label>
					<span>
						{localization.calculator.configurator.rate}
					</span>

					<div className={ratesBlockClass}>
						{/* список тарифов */}
						<Select defaultValue={selectedRate ? selectedRate.id : formattedRates[0]}
							value={ratesSelectorValue}
							className={ratesSelectorClass}
							onSelect={rateSelectedHandler}
							options={formattedRates}/>
					</div>
				</label>		
			</div>

			<div className={removeRateButtonBlock}>
				<Button onClick={removeRateButtonClicked}
					disabled={removeButtonBlocked}>
					{localization.common.remove}
				</Button>
			</div>	

			{/* название и описание тарифа */}
			<div className={rateNameAndDescriptionClass}>

				{rateNameDisabled? <label>Сохранение тарифов...</label> : ''}

				{/* название тарифа */}
				{selectedRate ? <label className={selectedRateBlockClass}
					style={{width: '350px'}}>
					<span style={{marginLeft: '-16px'}}>
						{localization.calculator.configurator.rateName}
					</span>
					<Input type="text"
						className={rateParamsClass}
						disabled={rateNameDisabled}
						value={rateName}
						onChange={rateNameChangedHandler}/>
				</label> : ''}

				<label className={selectedRateDescriptionBlockClass}
					style={{width: '350px'}}>
					<span style={{marginLeft: '-16px'}}>
						{localization.calculator.configurator.editor.description}
					</span>
					<Input type="text"
						className={rateParamsClass}
						disabled={rateDescriptionDisabled}
						value={rateDescription}
						onChange={rateDescriptionChangedHandler}
					/>
				</label>

				<div className={geographyButtonsClass}>
					<label className={selectedRateDescriptionBlockClass}
						style={{width: '350px'}}>
						<span style={{marginLeft: '-16px'}}>
						Страна отправления:
						</span>
						<span>
							{selectedRate?.source}
						</span>
					</label>

					<label className={selectedRateDescriptionBlockClass}
						style={{width: '350px'}}>
						<span style={{marginLeft: '-16px'}}>
						Город отправления:
						</span>
						<span>
							{selectedRate?.sourceCity}
						</span>
					</label>				

					<label className={selectedRateDescriptionBlockClass}
						style={{width: '350px'}}>
						<span style={{marginLeft: '-16px'}}>
						Страна назначения:
						</span>
						<span>
							{selectedRate?.destination}
						</span>
					</label>

					<label className={selectedRateDescriptionBlockClass}
						style={{width: '350px'}}>
						<span style={{marginLeft: '-16px'}}>
						Город назначения:
						</span>
						<span>
							{selectedRate?.destinationCity}
						</span>
					</label>
				</div>
				
				{/* Срок доставки в рабочих днях */}
				<div className={termsBlockClass}>
					<label>
						<span>
							{localization.calculator.configurator.editor.terms}
						</span>
						<Input type="number"
							value={rateTerms}
							className={termsInputClass}
							onChange={rateTermsChangedHandler}/>
					</label>
				</div>

			</div>

			<div className={ratesAndGeographyBlock}>
				Задать новые значения:
				{/* география тарифа */}
				<div className={countriesBlockClass}
					style={{lineHeight: '48px'}}>
					{/* страна отправителя */}
					<label>
						<span>
							{localization.calculator.configurator.senderCountry}
						</span>
						<Select className={senderCountrySelectClass}
							value={senderCountry.code}
							style={{width: '350px'}}
							onChange={senderCountrySelectedHandler}
							options={formatGeoItem(countries)}/>
					</label>

					{/* город отправления */}
					{isRuRuRate() ? <div>
						<label className={senderCityBlockClass}>
							<span>
								{localization.calculator.configurator.senderCity}
							</span>
							<Select
								className={senderCitySelectClass}
								value={senderCity ? senderCity.code : ''}
								style={{width: '350px'}}
								onChange={senderCitySelectedHandler}
								options={formatGeoItem(senderCities || [])}/>
						</label>
					</div> : null}
					


					{/* страна получателя */}
					<label className={destinationCountryBlockClass}>
						<span>
							{localization.calculator.configurator.destinationCountry}
						</span>
						<Select className={destinationCountrySelectClass}
							value={destinationCountry.code}
							style={{width: '350px'}}
							onChange={destinationCountrySelectedHandler}
							options={formatGeoItem(countries)}/>
					</label>

					{/* город получателя */}
					{isRuRuRate() ? <div>
						<label className={destinationCityBlockClass}>
							<span>
								{localization.calculator.configurator.destinationCity}
							</span>
							<Select
								className={destinationCitySelectClass}
								value={destinationCity ? destinationCity.code : ''}
								style={{width: '350px'}}
								onChange={destinationCitySelectedHandler}
								options={formatGeoItem(destinationCities || [])}/>
						</label>
					</div> : ''}
					
				</div>
			</div>
		</div>

		<div className={ratesByWeightClass}>
			{localization.calculator.configurator.editor.ratesByWeight}
		</div>
		{/* тарифы по массе */}
		<div>
			<div className={ratesAmountsBlockClass}>
				{/* тарифы по массе */}
				{/* Таблица с тарифами */}
				<Row>
					<Col span={8}
						className={ratesBlockCellClass}>
						{localization.calculator.configurator.columns.weight}
					</Col>
					<Col span={8}
						className={ratesBlockCellClass}>
						{localization.calculator.configurator.columns.netCost}
					</Col>
					<Col span={8}
						className={ratesBlockCellClass}>
						{localization.calculator.configurator.columns.grossCost}
					</Col>
				</Row>
				{selectedRate?.amounts.map((amount, index) =>
					<Row key={index}>

						{/* Вес тарифа */}
						<Col span={8}
							className={ratesBlockCellClass}>
							{amount.weight === UNLISTED_RATE_INDEX ? 
								localization.calculator.configurator.unlistedRate : amount.weight}
						</Col>

						{/* Существующее значение тарифа */}
						<Col span={8}
							className={ratesBlockCellClass}>
							{weightToNetCost[amount.weight] ? weightToNetCost[amount.weight].toFixed(2) : 0}
						</Col>

						{/* Новое значение тарифа */}
						<Col span={8}
							className={ratesBlockCellClass}>
							<Input type="number"
								className={netWeightInputClass}
								value={isNaN(newWeightToNetCost[amount.weight]) ? newWeightToNetCost[amount.weight] : newWeightToNetCost[amount.weight] + ''}
								onChange={netWeightSetHandler(amount.weight)}/>
						</Col>
					</Row>)}
			</div>

			{/* нижние управлюящие кнопки */}
			<div className={controlButtonsClass}>
				{/* подтведить */}
				<Button onClick={applyButtonClickedHandler}>
					{localization.common.apply}
				</Button>
			</div>
		</div>

		{/* управление географией */}
		<div className={geographyConfigurationTitleClass}>
			{localization.calculator.configurator.geographyConfiguration.title}
		</div>
		<Button type="default"
				onClick={() => setGeographyBlockEnabled(true)}>
			Развернуть
		</Button>
		<Button type="default"
				onClick={() => setGeographyBlockEnabled(false)}>
			Свернуть
		</Button>
		{geographyBlockEnabled ? <div className={geographyConfigurationContainerClass}>
			{/* страна */}
			<div className={geographyConfiguratorCitiesCountryClass}>
				<label>
					<span className={countryLabelClass}>
						{localization.calculator.configurator.geographyConfiguration.country}
					</span>
					<Select
						className={geographyCountriesClass}
						value={geographyCountry.code}
						style={{width: '350px'}}
						onChange={geographyCountrySelectedHandler}
						options={formatGeoItem(geographyCountries)}
					/>
				</label>
				<div>
					<Button type="default"
						className={removeCountryButtonClass}
		
						onClick={removeCountryButtonClickedHandler}>
						{localization.common.remove}
					</Button>
				</div>
				<div className={geographyConfigurationContriesControlButtons}>
					{/* Кнопка "Добавить" */}
					<Button type="default"
						className={addCountryButtonClass}
						onClick={addCountryButtonClickedHandler}>
						{localization.common.add}
					</Button>
				</div>
				<div>
					<Button type="default"
						className={addCountryButtonClass}
						onClick={applyCountriesClickedHandler}>
						{localization.common.save}
					</Button>
				</div>
			</div>
			{geographyCountry ?
				<div className={geographyCountryEditorClass}>
					<div className={countryNameClass}>
						{localization.calculator.configurator.geographyConfiguration.countryParams}
					</div>
					<div className={geographyCountryEditorContainerClass}>
						{/* код */}
						<label className={geographyCountryEditorCodeClass}>
							<span className={countryNameEditorTitle}>
								{localization.calculator.configurator.geographyConfiguration.countryCode}
							</span>

							<Input type="text"
								value={geographyCountry.code}
								onChange={geographyCountryCodeChangedHandler}/>
						</label>

						{/* название */}
						<label className={geographyCountryEditorNameClass}>
							<span>
								{localization.calculator.configurator.geographyConfiguration.countryName}
							</span>

							<Input type="text"
								value={geographyCountry.name}
								onChange={geographyCountryNameChangedHandler}/>
						</label>
					</div>

				</div> : ''}

			{/* список городов */}
			<div className={citiesListAndControlButtonsBlockClass}>
				{/* список городов */}
				<label className={geographyCitiesListBlock}>
					<span>
						{localization.calculator.configurator.geographyConfiguration.citiesList}
					</span>
					<select className={citiesSelectClass}
						size={10}>
						{
							(geographyCities.get(geographyCountry.code) || []).map((city: City) =>
								<option key={city.code + Math.random()}
									className={cityOptionClass}
									value={city.code}
									onClick={geographyCitySelectedHandler(city.code)}>
									{city.name}
								</option>)
						}
					</select>
				</label>

				{/* управляющие кнопки */}
				<div className={geographyButtonsClass}>
					{/* добавить наименование */}
					<Button type="default"
						className={cityButtonClass}
						disabled={geographyControlsDisabled}
						onClick={addCityButtonClickedHandler}>
						{localization.calculator.configurator.geographyConfiguration.controlButtons.add}
					</Button>

					{/* добавить списком */}
					<Button type="default"
						className={cityButtonClass}
						disabled={geographyControlsDisabled}
						onClick={addFromListButtonClickHandler}>
						{localization.calculator.configurator.geographyConfiguration.controlButtons.addFromList}
					</Button>
					<Input id="cities-list-input"
						onChange={uploadCitesList}
						disabled={geographyControlsDisabled}
						style={{display: 'none'}}
						type="file" />
					{/* удалить наименование */}
					<Button type="default"
						className={cityButtonClass}
						disabled={geographyControlsDisabled}
						onClick={removeCityButtonClickHandler}>
						{localization.calculator.configurator.geographyConfiguration.controlButtons.remove}
					</Button>

					{/* очистить */}
					<Button type="default"
						className={cityButtonClass}
						disabled={geographyControlsDisabled}
						onClick={clearButtonClickHandler}>
						{localization.calculator.configurator.geographyConfiguration.controlButtons.clear}
					</Button>

					{/* найти */}
					<Button type="default"
						className={cityButtonClass}
						disabled={geographyControlsDisabled}
						onClick={findCityButtonClickHandler}>
						{localization.calculator.configurator.geographyConfiguration.controlButtons.find}
					</Button>

				</div>
			</div>
			{geographyCity ?
				<div className={geographyCityEditorClass}>
					<div className={cityNameClass}>
						{localization.calculator.configurator.geographyConfiguration.cityParams}
					</div>
					<div className={geographyCityEditorContainerClass}>
						{/* код города */}
						<label className={geographyCityCodeBlockClass}>
							<span>
								{localization.calculator.configurator.geographyConfiguration.cityCode}
							</span>
							<Input type="text"
								value={geographyCity.code}
								disabled={geographyControlsDisabled}
								onChange={geographyCityCodeChangedHandler}
							/>
						</label>

						{/* название города */}
						<label className={geographyCityNameBlockClass}>
							<span>
								{localization.calculator.configurator.geographyConfiguration.cityName}
							</span>
							<Input type="text"
								value={geographyCity.name}
								disabled={geographyControlsDisabled}
								onChange={geographyCityNameChangedHandler}
							/>
						</label>
					</div>
					<div className={cityEditConfirmBlockClass}>
						<Button type="default"
							className={cityApplyButtonClass}
							onClick={cityApplyButtonClickedHandler}>
							{localization.common.apply}
						</Button>
						<Button type="default"
							onClick={cityCancelButtonClickedHandler}>
							{localization.common.cancel}
						</Button>
					</div>
				</div>
				: ''}

			{/* подтвердить */}
			<div className={geographyApplyButtonClass}>
				<Button type="default"
					onClick={geographyApplyClickedHandler}>
					{localization.common.apply}
				</Button>
			</div>
		</div> : null}
		

		<Modal title={localization.calculator.configurator.geographyConfiguration.controlButtons.citySearch}
			open={searchModalIsOpen}
			onOk={performCitySearch}
			onCancel={searchModalIsClosedHandler}>
			<p>
				{localization.calculator.configurator.geographyConfiguration.controlButtons.enterCityName}
			</p>
			<Input type="text"
				value={citySearchRequest}
				onChange={citySearchRequestChangedHandler}
			/>
		</Modal>
	</div>);
};