import { createBrowserRouter } from 'react-router-dom';
import authRoutes from './authRoutes';
import trackingRoutes from './trackingRoutes';
import calculatorRoutes from './calculatorRoutes';
import paymentRoutes from './paymentRoutes';

const allRoutes = createBrowserRouter([
	...authRoutes,
	...trackingRoutes,
	...calculatorRoutes,
	...paymentRoutes
]);

export default allRoutes;