import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { localization } from '../../../app/localization/ru';
import {Button, Col, Input, Modal, Row, Table} from 'antd';
import { PaymentCustomer } from './configuratorSlice';
import {createCustomer, createPaymentLink, fetchSimilarCustomers} from './configuratorApi';

/**
 * Компонент создания платежной ссылки
 *
 * @author Sergey Maksimenko
 * @since 22.12.23
 */
export const PaymentConfigurator = () => {
	//  в компоненте будут поля для задания параметров платежей
	// и кнопка создания платёжной ссылки

	const navigate = useNavigate();

	const [fio, setFio] = useState<string>();
	const [phone, setPhone] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [foundCustomers, setFoundCustomers] = useState<PaymentCustomer[]>();

	const [selectedCustomer, setSelectedCustomer] = useState<PaymentCustomer>();
	const [amount, setAmount] = useState<number>();
	const [purpose, setPurpose] = useState<string>();

	const [modalIsOpen, setModalIsOpen] = useState(false);

	const fioChangedHandler = (event: { target: { value: string } }) => {
		setFio(event.target.value);
	};

	const phoneChangedHandler = (event: { target: { value: string } }) => {
		setPhone(event.target.value);
	};

	const emailChangedHandler = (event: { target: { value: string } }) => {
		setEmail(event.target.value);
	};

	const findCustomerClickedHandler = async () => {
		if (!fio || !phone || !email) {
			return;
		}

		const customers = await fetchSimilarCustomers(fio, phone, email);
		setFoundCustomers(customers);
	};

	const addCustomerButtonClicked = async () => {
		if (!fio || !phone || !email) {
			return;
		}

		const user = await createCustomer(fio, phone, email);
		setSelectedCustomer(user);
	};
    
	const userLinkClickedHandler = (id: number) => () => {
		console.log('userLinkClickedHandler', id);
		if (foundCustomers) {
			const customer = foundCustomers.find((customer: PaymentCustomer) => customer.id === id);
			console.log('found customer', customer);
			if (customer) {
				setSelectedCustomer(customer);
			}
		}
	};

	const tableColumns = [
		{
			title: localization.payments.customer.fio,
			dataIndex: 'name',
			key: 'name',
			render: (name: number, record: PaymentCustomer) => <a onClick={userLinkClickedHandler(record.id)}>{name}</a>
		},
		{
			title: localization.payments.customer.phone,
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: localization.payments.customer.email,
			dataIndex: 'email',
			key: 'email'
		},
	];

	const formatUsers = () => {
		if (!foundCustomers) {
			return [];
		}

		return foundCustomers.map((customer: PaymentCustomer) => ({
			key: customer.id,
			...customer
		}));
	};

	const amountChangedHandler = (event: { target: { value: string } }) => {
		setAmount(parseFloat(event.target.value));
	};

	const purposeChangedHandler = (event: { target: { value: string } }) => {
		setPurpose(event.target.value);
	};

	const showConfirmModal = () => {
		setModalIsOpen(true);
	};

	const makeCreatePaymentLinkRequest = async () => {
		if (!selectedCustomer || !purpose || !amount) {
			return;
		}

		await createPaymentLink(selectedCustomer.customerCode, purpose, amount);
	};

	const contentBlockClass = 'p-4';
	const userParamsField = 'ml-4';
	const customerFindButtonBlockClass = 'justify-center mt-2 flex';
	const foundCustomersBlockClass = 'mt-2 text-center';
	const addCustomerButtonClass = 'ml-2';
	const foundCustomersClassBlock = 'mt-2';
	const paymentParamClass = 'mt-2';
	const sendPaymentLinkButtonClass = 'justify-center mt-2';
	const customersTitleClass = 'text-center';
	const selectedCustomerNameClass = 'ml-2';
	const selectedCustomerPhoneClass = 'ml-2';
	const selectedCustomerEmailClass = 'ml-2';
	return (
		<div className={contentBlockClass}>
			{/* тут будет компонент задания параметров платежа */}
			{selectedCustomer ? <div>
				{localization.payments.customer.selectedUser}
			</div> : ''}
			<div>
				<Row>
					{/* ФИО */}
					<Col className={userParamsField}
						 span={6}>
						<label>
							<span>{localization.payments.customer.fio}</span>

							{selectedCustomer ? (
								<span className={selectedCustomerNameClass}>{selectedCustomer.name}</span>
							) : (
								<Input value={fio} onChange={fioChangedHandler}/>
							)}
						</label>
					</Col>

					{/* телефон */}
					<Col className={userParamsField}
						 span={6}>
						<label>
							<span>{localization.payments.customer.phone}</span>

							{selectedCustomer ? (
								<span className={selectedCustomerPhoneClass}>{selectedCustomer.phone}</span>
							) : (
								<Input value={phone} onChange={phoneChangedHandler}/>
							)}
						</label>
					</Col>

					{/* email */}
					<Col className={userParamsField}
						 span={6}>
						<label>
							<span className={selectedCustomerEmailClass}>{localization.payments.customer.email}</span>

							{selectedCustomer ? (
								<span>{selectedCustomer.email}</span>
							) : (
								<Input value={email} onChange={emailChangedHandler}/>
							)}
						</label>
					</Col>
				</Row>

				{selectedCustomer ? '' :
					<div className={customerFindButtonBlockClass}>
						<Button onClick={findCustomerClickedHandler}>
							{localization.common.find}
						</Button>

						<Button
							className={addCustomerButtonClass}
							type="dashed"
							onClick={addCustomerButtonClicked}>
							{localization.common.add}
						</Button>
					</div>
				}

				{selectedCustomer ? '':
					<div>
						<span className={customersTitleClass}>
							{localization.payments.customer.foundCustomers}
						</span>
						<div className={foundCustomersBlockClass}>
							{foundCustomers && foundCustomers.length ?
								<Table className={foundCustomersClassBlock}
									columns={tableColumns}
									dataSource={formatUsers()}/> :
								<span>
									{localization.payments.customer.noCustomers}
								</span>
							}
						</div>
					</div>
				}
			</div>

			{/* сумма */}
			<div className={paymentParamClass}>
				<label>
					<span>{localization.payments.amount}</span>
					<Input
						type="number"
						value={amount}
						onChange={amountChangedHandler}
						suffix="руб"
					/>
				</label>
			</div>

			<div className={paymentParamClass}>
				<label>
					<span>
						{localization.payments.purpose}
						<Input value={purpose} onChange={purposeChangedHandler}/>
					</span>
				</label>
			</div>

			<div className={sendPaymentLinkButtonClass}>
				<Button onClick={showConfirmModal}>
					{localization.payments.createAndSave}
				</Button>
			</div>

			{/* модальное окно подтверждения платежа */}
			<Modal open={modalIsOpen}
				title={localization.common.confirmation}
				onOk={makeCreatePaymentLinkRequest}>
				{localization.payments.confirmationText}
			</Modal>
		</div>
	);
};
